import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_elements/helpers'

import {StepperComponent} from '../../../../_elements/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {getMenus} from './core/__request'
import {useAuth} from '../../../modules/auth'
import Step from './Step'
import {useCommentDrawer, useStep} from './Hooks'
import {ToastContainer, toast} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import {useStatusContext} from '../../../Context/StatusContext'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useStepContext} from '../../../Context/StepContext'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MenuUserLoading from '../../../../_elements/loading-skeleton/MenuUserLoading'

const Vertical = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [isLoading, SetIsLoading] = useState(true)
  const [menus, setMenus] = useState([])
  const [checkMenus, setCheckMenus] = useState([])
  const {currentUser, auth} = useAuth()
  let status_request = localStorage.getItem('STATUS_REQUEST')
  const [statusRequest, setStatusRequest] = useState(Number(status_request))
  const {data: stepData, updateStep} = useStepContext()

  const {data, updateStatus} = useStatusContext()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement, {
      startIndex: 1,
      animation: true,
      animationSpeed: '500',
      animationNextClass: 'next-animation',
      animationPreviousClass: 'previous-animation',
    })
  }
  // useEffect(() => {
  //   updateStep({
  //     menus: menus,
  //     status: statusRequest,
  //     checkMenus: checkMenus,
  //   })
  // }, [statusRequest])

  useEffect(() => {
    updateStatus(currentUser?.['status_payment'])
    updateStep({
      menus: menus,
      status: statusRequest,
      checkMenus: checkMenus,
      statusRequest: auth?.['data']['request']['status_request'],
    })
    getMenus().then((response) => {
      setMenus(response.data['menus'])
      setCheckMenus(response.data['menus_check'])

      SetIsLoading(false)
    })
  }, [])
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()

    if (!stepper.current) {
      return
    }
    stepper.current.goto(stepper.current.getCurrentStepIndex())
  },[])

  const back = () => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()

    if (!stepper.current) {
      return
    }
    setStatusRequest(statusRequest - 1)

    stepper.current.goPrev()
  }

  const next = (step_number) => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()

    if (!stepper.current) {
      return
    }
    updateStep({
      menus: menus,
      status: statusRequest,
      checkMenus: checkMenus,
      statusRequest: step_number,
    })
    setStatusRequest(step_number)
    localStorage.setItem('STATUS_REQUEST', step_number)
    stepper.current.goto(step_number)

    if (data == 1) {
      return toast.success('The operation was successfuly', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        icon: 'success',
        title: 'Your work has been saved',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        confirmButtonColor: 'rgb(70, 196, 127)',
      })
    }
  }
  const dra = useCommentDrawer()
  const [style, setStyle] = useState({})

  useEffect(() => {
    setStyle(!dra?.drawer ? {width: '75%'} : {})
  }, [dra])

  if (isLoading) {
    return <MenuUserLoading />
  }

  return (
    <div>
      {data == 2 ? (
        <div className='alert alert-primary d-flex align-items-center p-5'>
          <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          <div className='d-flex flex-column'>
            <h4 className='mb-1 text-dark'>This is an alert</h4>
            <span>
              The alert component can be used to highlight certain parts of your page for higher
              content visibility.
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div
        ref={stepperRef}
        style={style}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {checkMenus.map((value) => (
                <div className='stepper-item completed'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>{value?.['step_number']}</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>{value?.['name']}</h3>

                      <div className='stepper-desc fw-semibold'>Setup Your Account Details</div>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
              ))}
            </div>
            <div className='stepper-nav'>
              {menus.map((value, index) => {
                return (
                  <div
                    className={
                      statusRequest !== value['step_number']
                        ? statusRequest > value['step_number']
                          ? 'stepper-item completed'
                          : 'stepper-item'
                        : 'stepper-item current'
                    }
                    data-kt-stepper-element='nav'
                    onClick={() => {
                      if (statusRequest >= value['step_number']) {
                        stepper.current?.goto(value['step_number'])
                      }
                    }}
                  >
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{value['step_number']}</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{value['name']}</h3>
                        <div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                )
              })}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          {menus.map((value) => {
            if (value['step_number'] <= statusRequest) {
              return (
                <div
                  className={statusRequest == value['step_number'] ? 'current' : ''}
                  data-kt-stepper-element='content'
                >
                  <Step step_number={value['step_number']} next={next} back={back} />
                </div>
              )
            }
          })}
        </div>
        <ToastContainer />
      </div>
    </div>
  )
}

export {Vertical}
