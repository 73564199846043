import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_elements/layout/MasterLayout'
import {DashboardWrapper} from '../pages/admin/dashboard/DashboardWrapper'
import UsersPage from '../pages/admin/users-list/UsersPage'
import ApplyRequestsPage from '../pages/admin/requests-list/ApplyRequestsPage'
import ChatPage from '../pages/admin/chat/ChatPage'
import {AdminLayout} from '../../_elements/layout/AdminLayout'
import ProfilePage from '../pages/admin/profile/ProfilePage'
import {Toast} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {NotificationContextProvider, useNotificationContext} from '../Context/NotificationContext'
import {MainPusher} from '../../_requests/_pusher_main'
import {useTokenContext} from '../Context/TokenContext'
import { toAbsoluteUrl } from '../../_elements/helpers'

const AdminRoutes = () => {
  const [show, setShow] = useState(false)
  const [nofiyData, setNofiyData] = useState({})
  const {data: token} = useTokenContext()

  useEffect(() => {
    var channel = MainPusher(token)?.subscribe(`private-notification`)
    channel?.bind('NotificationEvent', function (data) {
      setShow(true)
      const audio = new Audio(toAbsoluteUrl('/media/noth.mp3'))
      audio.play()
      setNofiyData(data.item)
    })
  }, [])

  return (
    <NotificationContextProvider>
      <Routes>
        <Route element={<AdminLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='/users/*' element={<UsersPage />} />
          <Route path='/profile/*' element={<ProfilePage />} />
          <Route path='/requests/*' element={<ApplyRequestsPage />} />
          <Route path='/chat/*' element={<ChatPage />} />
        </Route>
      </Routes>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={5000}
        autohide
        style={{position: 'fixed', top: '88%', left: '3%', zIndex: '105'}}
      >
        <Toast.Header>
          <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
          <strong className='me-auto'>{nofiyData?.['username']}</strong>
          <small>{nofiyData?.['type']}</small>
        </Toast.Header>
        <Toast.Body>{nofiyData?.['message']}</Toast.Body>
      </Toast>
    </NotificationContextProvider>
  )
}

export {AdminRoutes}
