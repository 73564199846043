import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_elements/assets/ts/_utils'
import {WithChildren} from '../../_elements/helpers'
import {Vertical} from '../pages/user/dashboard/Vertical'
import {UserLayout} from '../../_elements/layout/UserLayout'
import {StatusContextProvider} from '../Context/StatusContext'
import {StepContextProvider} from '../Context/StepContext'

const UserRoutes = () => {
  // const applyPage = lazy(()=>import(''))
  return (
    <StatusContextProvider>
      <StepContextProvider>
        <Routes>
          <Route element={<UserLayout />}>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
            {/* Pages */}
            <Route path='dashboard' element={<Vertical />} />

            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Route>
        </Routes>
      </StepContextProvider>
    </StatusContextProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserRoutes}
