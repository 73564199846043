import {FC, useMemo} from 'react'
import {ID} from '../../../../../../../_elements/helpers'
import {useListView} from '../../core/ListViewProvider'



const ApplyRequestSelectionCell= ({num}) => {
  return <div className='form-check form-check-custom form-check-solid'>{num}</div>
}

export {ApplyRequestSelectionCell}
