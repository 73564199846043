import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; // Import the CSS

type ImageCropperProps = {
  src: string;
  onCrop: (croppedImageBlob: Blob) => void;
};

const ImageCropper: React.FC<ImageCropperProps> = ({ src, onCrop }) => {
  const cropperRef = useRef<HTMLImageElement>(null);

  const cropImage = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob: Blob) => {
        onCrop(blob)
    });
  };

  return (
    <div>
      <Cropper
        ref={cropperRef}
        src={src}
        style={{  width: '100%' }}
        // Cropper.js options
        aspectRatio={1 / 1}
        guides={false}
        crop={cropImage}
      />
    </div>
  );
};

export default ImageCropper;