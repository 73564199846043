import {ErrorMessage, Field, Formik, Form} from 'formik'
import {KTIcon} from '../../../../../_elements/helpers'
import {ApplyValidation} from './core/validation/Validation'
import {GetData, GetCountry, SendData} from './core/Requests/__requests_apply'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useStatusContext} from '../../../../Context/StatusContext'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import ApplyStepUserLoading from '../../../../../_elements/loading-skeleton/ApplyStepUserLoading'

const Apply = ({step_number, next, back}) => {
  const [loading, setLoading] = useState(true)
  const [inits, setInits] = useState({
    country_insert: 'Canada',
    level_insert: 'P.H.D',
    field_insert: 'P.H.D',
  })
  const [country, setCountry] = useState([])
  const {data, updateStatus} = useStatusContext()
  const MySwal = withReactContent(Swal)

  useEffect(() => {
    if (data == 2 || data == 4) {
      Swal.fire({
        title: '<strong>Complete steps</strong>',
        icon: 'info',
        html: `
    You can use <b>bold text</b>,
    <a href="#">links</a>,
    and other HTML tags
  `,
        showCloseButton: true,

        focusConfirm: false,
        confirmButtonText: `
 Change data!
  `,
      })
    }

    if (data == 5) {
      Swal.fire({
        title: '<strong>Complete steps</strong>',
        icon: 'info',
        html: `
    You can use <b>bold text</b>,
    <a href="#">links</a>,
    and other HTML tags
  `,
        showCloseButton: true,

        focusConfirm: false,
        confirmButtonText: `
 Go Help page
  `,
      })
    }
    const requests = [GetData(), GetCountry()]
    Promise.all(requests)
      .then((responses) => {
        if (responses?.[0]['data']['data'] != null) {
          setInits(responses?.[0]['data']['data'])
        }
        const countryData = responses?.[1]?.data
        if (countryData != null) {
          setCountry(countryData)
        }
        setLoading(false)
      })
      .catch((error) => {})
  }, [])

  const submitRequest = async (value) => {
    await SendData(value)
      .then((response) => {
        updateStatus(2)
        next(response.data.status.status_request)

        MySwal.fire({
          icon: 'success',
          title: 'Your work has been saved',
          confirmButtonText: 'Ok',
          showConfirmButton: true,
          confirmButtonColor: 'rgb(70, 196, 127)',
          backdrop: `
rgba(0, 151, 24, 0.26)
  `,
        })
      })
      .catch((errors) => {})
  }

  if (loading) {
    return <ApplyStepUserLoading />
  }
  return (
    <div>
      <Formik validationSchema={ApplyValidation} initialValues={inits} onSubmit={submitRequest}>
        {({errors, touched}) => (
          <Form className='py-20 w-100 w-xl-700px px-9' id='kt_create_account_form'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>Personal</h2>

                <div className='text-gray-400 fw-bold fs-6'>If you need more change email.</div>
              </div>

              <div className='mb-10 fv-row'>
                <label className='d-flex align-items-center form-label  required mb-3'>
                  Select country insert
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Field
                      className={clsx(
                        'form-select ',
                        {'is-invalid': touched.country_insert && errors.country_insert},
                        {
                          'is-valid': touched.country_insert && !errors.country_insert,
                        }
                      )}
                      name='country_insert'
                      id='country_insert'
                      as='select'
                    >
                      {country != null ? (
                        country.map((val) => {
                          return <option key={val?.['id']}>{val?.['name']}</option>
                        })
                      ) : (
                        <option></option>
                      )}
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='country_insert' className='text-danger' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <label className='d-flex align-items-center form-label  required mb-3'>
                  Select filed insert
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Field
                      className={clsx(
                        'form-select ',
                        {'is-invalid': touched.field_insert && errors.field_insert},
                        {
                          'is-valid': touched.field_insert && !errors.field_insert,
                        }
                      )}
                      name='field_insert'
                      id='field_insert'
                      as='select'
                    >
                      <option>Canada</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='field_insert' className='text-danger' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <label className='d-flex align-items-center form-label  required mb-3'>
                  Select level insert
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Field
                      className={clsx(
                        'form-select ',
                        {'is-invalid': touched.level_insert && errors.level_insert},
                        {
                          'is-valid': touched.level_insert && !errors.level_insert,
                        }
                      )}
                      name='level_insert'
                      id='level_insert'
                      as='select'
                    >
                      <option>Canada</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='level_insert' className='text-danger' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-stack pt-10'>
              <div className='mr-2'>
                <button
                  onClick={() => back()}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              </div>

              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  <span className='indicator-label'>
                    Submit and Next
                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Apply
