import {FC} from 'react'


type Props = {
    rule?: string
}

const RuleCell: FC<Props> = ({rule}) => {
  return (
    <div className={`badge badge-${rule=='admin'?'success':'danger'} fw-bolder`}>{rule}</div>
  )
}

export {RuleCell}
