import axios, {AxiosResponse} from 'axios'
import Axios from '../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/admin/users/`
const GET_ADMIN_URL = `${API_URL}/admin/users/all-admin`
const USER_URL = `${API_URL}/admin/users`

const userData = async (id) => {
  return Axios.get(`${GET_USERS_URL}${id}`)
}

const updateUser = (user) => {
  return Axios.patch(`${USER_URL}/${user.id}`, user)
}

const getAdmin = () => {
  return Axios.get(`${GET_ADMIN_URL}`)
}
export {userData, updateUser, getAdmin}
