import {ErrorMessage, Field, Formik, Form, FieldArray} from 'formik'
import {KTIcon} from '../../../../../_elements/helpers'
import {DocumentValidation} from './core/validation/Validation'
import {DeleteUpload, GetData, SendData, Upload} from './core/Requests/__requests_documents'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import UploadFile from './core/components/UploadFile'
import ApplyStepUserLoading from '../../../../../_elements/loading-skeleton/ApplyStepUserLoading'

const Document = ({step_number, next, back}) => {
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState({
    passport: '[]',
    national_id: '[]',
    education: '[]',
    language: '[]',
    CV: '[]',
    GRE: '[]',
    other: '[]',
  })

  useEffect(() => {
    const requests = [GetData()]
    Promise.all(requests)
      .then((responses) => {
        setLoading(false)
        if (responses?.[0] != null) {
          const data = responses?.[0]['data']['data']
          setFiles({
            ...files,
            passport: data.passport,
            national_id: data.national_id,
            education: data.education,
            language: data.language,
            CV: data.CV,
            GRE: data.GRE,
            other: data.other,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const submitRequest = async (value) => {
    await SendData(files)
      .then((response) => {
        next(6)
      })
      .catch((errors) => {})
  }

  const upload = async (field) => {
    return await Upload(field).then((res) => res.data)
  }
  const deleteApi = async (field) => {
    return await DeleteUpload(field).then((res) => res.data)
  }
  if (loading) {
    return <ApplyStepUserLoading />
  }
  const fileData = (data, field) => {
    setFiles({...files, [field.field]: JSON.stringify(data)})
  }

  return (
    <div>
      <form className='py-20 w-100 w-xl-700px px-9' id='kt_create_account_form'>
        <div className='w-100'>
          <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder text-dark'>Language</h2>

            <div className='text-gray-400 fw-bold fs-6'>If you need more change email.</div>
          </div>
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>Passport</h2>

              <div className='text-gray-400 fw-bold fs-6'>Select passport files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.passport)}
              field={{field: 'passport'}}
              upload={upload}
              deleteApi={deleteApi}
            />
          </div>{' '}
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>national id</h2>

              <div className='text-gray-400 fw-bold fs-6'>Select national id files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.national_id)}
              field={{field: 'national_id'}}
              upload={upload}
              deleteApi={deleteApi}
            />
          </div>{' '}
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>education</h2>

              <div className='text-gray-400 fw-bold fs-6'>Select education files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.education)}
              field={{field: 'education'}}
              upload={upload}
              deleteApi={deleteApi}
            />
          </div>{' '}
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>Language</h2>

              <div className='text-gray-400 fw-bold fs-6'>ISelect language files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.language)}
              deleteApi={deleteApi}
              field={{field: 'language'}}
              upload={upload}
            />
          </div>{' '}
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>CV</h2>

              <div className='text-gray-400 fw-bold fs-6'>Select CV files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.CV)}
              field={{field: 'CV'}}
              upload={upload}
              deleteApi={deleteApi}
            />
          </div>{' '}
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>GRE</h2>

              <div className='text-gray-400 fw-bold fs-6'>Select GRE files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.GRE)}
              field={{field: 'GRE'}}
              upload={upload}
              deleteApi={deleteApi}
            />
          </div>
          <div className='m-10'>
            <div className='pb-5'>
              <h2 className='fw-bolder text-dark'>Other</h2>

              <div className='text-gray-400 fw-bold fs-6'>Select other files.</div>
            </div>
            <UploadFile
              data={fileData}
              defaultData={JSON.parse(files.other)}
              field={{field: 'other'}}
              upload={upload}
              deleteApi={deleteApi}
            />
          </div>
        </div>
        <div className='d-flex flex-stack pt-10'>
          <div className='mr-2'>
            <button
              onClick={() => back()}
              type='button'
              className='btn btn-lg btn-light-primary me-3'
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1' />
              Back
            </button>
          </div>

          <div>
            <button
              type='button'
              onClick={() => submitRequest('1')}
              className='btn btn-lg btn-primary me-3'
            >
              <span className='indicator-label'>
                Submit and Next
                <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Document
