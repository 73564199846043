import {ErrorMessage, Field, Formik, Form, FieldArray} from 'formik'
import {KTIcon} from '../../../../../_elements/helpers'
import {LanguageValidation} from './core/validation/Validation'
import {GetData, SendData} from './core/Requests/__requests_language'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import ApplyStepUserLoading from '../../../../../_elements/loading-skeleton/ApplyStepUserLoading'

const Language = ({step_number, next, back}) => {
  const [loading, setLoading] = useState(true)
  const [inits, setInits] = useState({
    language: [
      {
        certificate: 'TOEFL',
        reading_score: '',
        listening_score: '',
        specking_score: '',
        writing_score: '',
        score: '',
      },
    ],
  })

  useEffect(() => {
    const requests = [GetData()]
    Promise.all(requests)
      .then((responses) => {
        if (responses?.[0]['data']['data'] != null) {
          const data = responses?.[0]['data']['data']
          setInits({
            language: JSON.parse(data.language),
          })
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const submitRequest = async (value) => {
    await SendData(value)
      .then((response) => {
        next(response.data.status.status_request)
      })
      .catch((errors) => {})
  }
  if (loading) {
    return <ApplyStepUserLoading />
  }

  return (
    <div>
      <Formik validationSchema={LanguageValidation} initialValues={inits} onSubmit={submitRequest}>
        {({errors, touched, values}) => (
          <Form className='py-20 w-100 w-xl-700px px-9' id='kt_create_account_form'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>Language</h2>

                <div className='text-gray-400 fw-bold fs-6'>If you need more change email.</div>
              </div>

              <div className='border border-secondary p-6'>
                <FieldArray
                  name='language'
                  render={(arrayHelpers) => (
                    <div>
                      <button
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            certificate: '',
                            reading_score: '',
                            listening_score: '',
                            speaking_score: '',
                            writing_score: '',
                          })
                        }
                        className='btn btn-icon btn-success '
                        style={{float: 'right', marginTop: 15, borderRadius: '25%'}}
                      >
                        <i className='fas fa-plus fs-4'></i>
                      </button>
                      {values.language.map((language, index) => (
                        <div key={index}>
                          <button
                            className='btn btn-icon btn-danger m-5'
                            type='button'
                            onClick={() => arrayHelpers.remove(index)}
                            style={{float: 'right', borderRadius: '25%'}}
                          >
                            <i className='fas  fs-4'>-</i>
                          </button>
                          <div className='pb-10 pb-lg-15'>
                            <h2 className='fw-bolder text-dark'>language</h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              If you need more change email.
                            </div>
                          </div>

                          <div className='mb-10 fv-row'>
                            <div className='row mb-2' data-kt-buttons='true'>
                              <div className='col'>
                                <label className='d-flex align-items-center form-label  required mb-3'>
                                  Write your certificate
                                </label>
                                <Field
                                  className={clsx(
                                    'form-select ',
                                    {
                                      'is-invalid':
                                        touched.language?.[index]?.certificate &&
                                        errors?.language?.[index]?.['certificate'],
                                    },
                                    {
                                      'is-valid':
                                        touched.language?.[index]?.certificate &&
                                        !errors?.language?.[index]?.['certificate'],
                                    }
                                  )}
                                  name={`language[${index}].certificate`}
                                  id={`language[${index}].certificate`}
                                  as='select'
                                >
                                  <option value={'TOEFL'}>TOEFL</option>
                                </Field>
                                <div className='text-danger mt-2'>
                                  <ErrorMessage
                                    name={`language[${index}].certificate`}
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='mb-10 fv-row'>
                            <div className='row mb-2' data-kt-buttons='true'>
                              <div className='col-3'>
                                <label className='d-flex align-items-center form-label  required mb-3'>
                                  speaking score
                                </label>
                                <Field
                                  className={clsx(
                                    'form-control ',
                                    {
                                      'is-invalid':
                                        touched.language?.[index]?.specking_score &&
                                        errors?.language?.[index]?.['specking_score'],
                                    },
                                    {
                                      'is-valid':
                                        touched.language?.[index]?.specking_score &&
                                        !errors?.language?.[index]?.['specking_score'],
                                    }
                                  )}
                                  name={`language[${index}].specking_score`}
                                  type='number'
                                  placeholder='your specking score'
                                  id={`language[${index}].specking_score`}
                                />

                                <div className='text-danger mt-2'>
                                  <ErrorMessage
                                    name={`language[${index}].specking_score`}
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                              <div className='col-3'>
                                <label className='d-flex align-items-center form-label  required mb-3'>
                                  listening score
                                </label>
                                <Field
                                  className={clsx(
                                    'form-control ',
                                    {
                                      'is-invalid':
                                        touched.language?.[index]?.listening_score &&
                                        errors?.language?.[index]?.['listening_score'],
                                    },
                                    {
                                      'is-valid':
                                        touched.language?.[index]?.listening_score &&
                                        !errors?.language?.[index]?.['listening_score'],
                                    }
                                  )}
                                  name={`language[${index}].listening_score`}
                                  id={`language[${index}].listening_score`}
                                  type='number'
                                  placeholder='your listening score'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage
                                    name={`language[${index}].listening_score`}
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                              <div className='col-3'>
                                <label className='d-flex align-items-center form-label  required mb-3'>
                                  reading score
                                </label>
                                <Field
                                  className={clsx(
                                    'form-control ',
                                    {
                                      'is-invalid':
                                        touched.language?.[index]?.reading_score &&
                                        errors?.language?.[index]?.['reading_score'],
                                    },
                                    {
                                      'is-valid':
                                        touched.language?.[index]?.reading_score &&
                                        !errors?.language?.[index]?.['reading_score'],
                                    }
                                  )}
                                  name={`language[${index}].reading_score`}
                                  id={`language[${index}].reading_score`}
                                  type='number'
                                  placeholder='your reading score'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage
                                    name={`language[${index}].reading_score`}
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                              <div className='col-3'>
                                <label className='d-flex align-items-center form-label  required mb-3'>
                                  writing score
                                </label>
                                <Field
                                  className={clsx(
                                    'form-control ',
                                    {
                                      'is-invalid':
                                        touched.language?.[index]?.writing_score &&
                                        errors?.language?.[index]?.['writing_score'],
                                    },
                                    {
                                      'is-valid':
                                        touched.language?.[index]?.writing_score &&
                                        !errors?.language?.[index]?.['writing_score'],
                                    }
                                  )}
                                  name={`language[${index}].writing_score`}
                                  id={`language[${index}].writing_score`}
                                  type='number'
                                  placeholder='your writing score'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage
                                    name={`language[${index}].writing_score`}
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='mb-10 fv-row'>
                            <div className='row mb-2' data-kt-buttons='true'>
                              <div className='col'>
                                <label className='d-flex align-items-center form-label  required mb-3'>
                                  Write your score
                                </label>
                                <Field
                                  className={clsx(
                                    'form-control ',
                                    {
                                      'is-invalid':
                                        touched.language?.[index]?.score &&
                                        errors?.language?.[index]?.['score'],
                                    },
                                    {
                                      'is-valid':
                                        touched.language?.[index]?.score &&
                                        !errors?.language?.[index]?.['score'],
                                    }
                                  )}
                                  name={`language[${index}].score`}
                                  id={`language[${index}].score`}
                                  type='number'
                                  placeholder='your score'
                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage
                                    name={`language[${index}].score`}
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className='d-flex flex-stack pt-10'>
              <div className='mr-2'>
                <button
                  onClick={() => back()}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              </div>

              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  <span className='indicator-label'>
                    Submit and Next
                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Language
