import Axios from '../../../../../../../_requests/_request_main'


const API_URL = process.env.REACT_APP_API_URL

export const VERIFY_EMAIL = `${API_URL}/step/update/verify-email`
export const  CHANGE_EMAIL= `${API_URL}/step/update/change-email`
export const  RESEND_EMAIL = `${API_URL}/step/update/verify-email-rend`


export function VerifyEmailRequest(code) {

  return Axios.post(VERIFY_EMAIL,{code:code})
}

export function ResendEmailRequest() {

  return Axios.post<{result: boolean}>(RESEND_EMAIL)
}

export function ChangeEmailRequest(item) {

  return Axios.post(CHANGE_EMAIL,item)
}
