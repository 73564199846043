import * as Yup from 'yup'

export const VerifyEmailValidation = Yup.object({
  code: Yup.number().required('This field is required')
})
export const ChangeEmailValidation = Yup.object({
  email: Yup.string().email().required('This field is required'),
})
export const PersonalValidation = Yup.object({
  phone: Yup.string().max(20).required('This field is required'),
  mailing: Yup.string().max(100).required('This field is required'),
  emergency: Yup.string().max(20).required('This field is required'),
  address: Yup.string().max(100).required('This field is required'),
  given_name: Yup.string().max(50).required('This field is required'),
  family_name: Yup.string().max(50).required('This field is required'),
  middle_name: Yup.string().max(50),
  birth_date: Yup.string().max(15).required('This field is required'),
  passport_number: Yup.string().max(20).required('This field is required'),
  passport_expire: Yup.string().required('This field is required'),
  country_citizenship: Yup.string().required('This field is required'),
  gender: Yup.string().required('This field is required'),
})

export const EducationValidation = Yup.object({
  education_level: Yup.string().max(20).required('This field is required'),
  country_education: Yup.string().max(20).required('This field is required'),
  field_study: Yup.string().max(20).required('This field is required'),
  year_graduation: Yup.string().max(20).required('This field is required'),
  GPA: Yup.string().max(20).required('This field is required'),
  education_history: Yup.array().of(
    Yup.object().shape({
      school_name: Yup.string().required('This field is required'),
      field_study: Yup.string().required('This field is required'),
      level: Yup.string().required('This field is required'),
      language_study: Yup.string().required('This field is required'),
      medium_instruction: Yup.string().required('This field is required'),
      address: Yup.string().required('This field is required'),
      studied_from: Yup.string().required('This field is required'),
      graduated_at: Yup.string().required('This field is required'),
      GPA: Yup.string().required('This field is required'),
    })
  ),
})

export const LanguageValidation = Yup.object({
  language: Yup.array().of(
    Yup.object().shape({
      certificate: Yup.string().required('This field is required'),
      reading_score: Yup.string().required('This field is required'),
      listening_score: Yup.string().required('This field is required'),
      specking_score: Yup.string().required('This field is required'),
      writing_score: Yup.string().required('This field is required'),
      score: Yup.string().required('This field is required'),
    }))
})

export const DocumentValidation = Yup.object({

})

export const ApplyValidation = Yup.object({
  country_insert: Yup.string().required('This field is required'),
  level_insert: Yup.string().required('This field is required'),
  field_insert: Yup.string().required('This field is required'),
})

export const Payment = Yup.object({
  code: Yup.string().label('Account Type'),
})
