import axios from 'axios'
import Axios from '../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const CHAT = `${API_URL}/comment/`

// Server should return AuthModel
export function getMessage(step_id) {
  return Axios.get(`${CHAT}${step_id}`)
}
export function send(message, step_id) {
  return Axios.post(`${CHAT}${step_id}`, {message: message})
}