import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ChatLoading = () => {
  return (
    <div className='row'>
      <div className='col-4'>
        <Skeleton height={700} />
      </div>
      <div className='col-8'>
        <Skeleton height={700} />
      </div>
    </div>
  )
}
export default ChatLoading
