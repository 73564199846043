import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_elements/helpers'
import {User, UsersQueryResponse} from './_models'
import Axios from '../../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/admin/users`
const CREATE_USER_URL = `${API_URL}/admin/users`
const GET_USERS_URL = `${API_URL}/admin/users`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return Axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = async (id: ID): Promise<User | undefined> => {
  return await Axios
    .get(`${USER_URL}/${id}`)
}

const createUser = (user: User): Promise<User | undefined> => {
  return Axios
    .put(CREATE_USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return Axios
    .patch(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return Axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => Axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
