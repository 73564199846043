import Axios from '../../../../../../../_requests/_request_main'


const API_URL = process.env.REACT_APP_API_URL

export const APPLY = `${API_URL}/step/get/apply`
export const APPLY_SEND = `${API_URL}/step/update/apply`
export const  COUNTRY= `${API_URL}/country/v1`


export function GetData() {

  return Axios.post(APPLY)
}

export function GetCountry() {

  return Axios.get<{result: boolean}>(COUNTRY)
}

export function SendData(data) {

    return Axios.post(APPLY_SEND,data)

  }
  