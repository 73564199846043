import React, {useEffect, useRef, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {toAbsoluteUrl} from '../../../../../../../_elements/helpers'
import clsx from 'clsx'
import {Modal, Button} from 'react-bootstrap'
import ImageCropper from './ImageCropper'
import {UploadProfile} from '../Requests/__requests_personal'
import {blob} from 'stream/consumers'
const UploadFile = ({
  data,
  field = {},
  defaultData = [] as any[],
  crop = false,
  upload,
  deleteApi,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [imageHover, setImageHover] = useState('')

  const [imageCropped, setImageCropped] = useState({})
  useEffect(() => {
    setSelectedFiles(defaultData)
   
  }, [])

  const onDrop = async (acceptedFiles) => {
    setLoading(true)

    if (acceptedFiles.length > 0) {
      const formData = new FormData()

      acceptedFiles.forEach((file) => {
        formData.append('file[]', file)
      })

      Object.entries(field).forEach((val) => {
        if (Array.isArray(val[1])) {
          val[1].map((value) => {
            formData.append(`${val[0]}[]`, value)
          })
        } else {
          formData.append(`${val[0]}`, val[1] as string)
        }
      })
      try {
        setLoading(true)
        let response = await upload(formData)
        if (response == undefined) {
          return 
        }
        setLoading(false)
        setSelectedFiles(response)
        data(response, field)
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
    onDrop,
  })
  const handleDelete = async (file) => {
      const formData = new FormData()

      formData.append('file',file)

      Object.entries(field).forEach((val) => {
        if (Array.isArray(val[1])) {
          val[1].map((value) => {
            formData.append(`${val[0]}[]`, value)
          })
        } else {
          formData.append(`${val[0]}`, val[1] as string)
        }
      })
      try {
        setLoading(true)
        let response = await deleteApi(formData)
        setLoading(false)
        setSelectedFiles(response)
        data(response, field)
      } catch (error) {
        console.error('Error:', error)
      }
  }

  return (
    <div className='form'>
      <div className='fv-row'>
        <div className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <div {...getRootProps()} className='dz-message needsclick'>
            <i className='ki-duotone ki-file-up fs-3x text-primary'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <div className='ms-4'>
              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                {isDragActive ? 'Drop the files here' : 'Drop files here or click to upload.'}
              </h3>
              <span className='fs-7 fw-semibold text-gray-400'>Upload up to 10 files</span>
            </div>
          </div>

          <input {...getInputProps()} accept='' />
          {selectedFiles.length > 0 && (
            <div>
              <h4>Selected Files:</h4>

              <div className='row ' style={{width: '100%'}}>
                {selectedFiles.map((file) => {
                  let format = file.toString().split('.')
                  format = format[format.length-1]

                  if (['jpeg', 'jpg', 'png'].includes(format)) {
                    return (
                      <div
                        key={file.toString()}
                        style={{transition: '1s'}}
                        className={clsx(
                          'mb-2 mt-2 grid ',
                          selectedFiles.length <= 4 ? `col-${12 / selectedFiles.length}` : 'col-3'
                        )}
                      >
                        <div
                          style={{
                            width: 100,
                            height: 100,
                            backgroundImage: `url("${file.toString()}")`,
                            backgroundSize: 'cover',
                            transition: '1s',
                          }}
                          onMouseEnter={() => setImageHover(file.toString())}
                          onMouseLeave={() => setImageHover('')}
                        >
                          <button
                            className={clsx(
                              'g-col-2 btn btn-danger fa-solid fa-eraser',
                              file.toString() == imageHover ? 'opacity-75' : 'opacity-0'
                            )}
                            style={{transition: '0.5s'}}
                            onClick={() => handleDelete(file)}
                            type='button'
                          >
                            {/* delete */}
                          </button>
                        </div>
                      </div>
                    )
                  }
                  if (format == undefined || !['pdf','txt','xml','doc'].includes(format)) {
                    setSelectedFiles(selectedFiles.filter((val) => val != file))
                  }
                  return (
                    <div
                      key={file.toString()}
                      style={{transition: '1s'}}
                      className={clsx(
                        'mb-2 mt-2 grid ',
                        selectedFiles.length <= 4 ? `col-${12 / selectedFiles.length}` : 'col-3'
                      )}
                    >
                      <div
                        style={{
                          width: 100,
                          height: 100,
                          backgroundImage: `url("${toAbsoluteUrl(
                            `/media/svg/files/${format}.svg`
                          )}")`,
                          backgroundSize: 'cover',
                          transition: '1s',
                        }}
                        onMouseEnter={() => setImageHover(file.toString())}
                        onMouseLeave={() => setImageHover('')}
                      >
                        <button
                          className={clsx(
                            'g-col-2 btn btn-danger fa-solid fa-eraser',
                            file.toString() == imageHover ? 'opacity-75' : 'opacity-0'
                          )}
                          style={{transition: '0.5s'}}
                          onClick={() => {
                            handleDelete(file)
                          }}
                          type='button'
                        >
                          {/* delete */}
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadFile
