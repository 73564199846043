/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/users'
        title='Users'
        icon='profile-circle'
        fontIcon='bi-person'
      />

      <SidebarMenuItem
        to='/requests'
        title='request'
        icon='briefcase'
        fontIcon='bi-person'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItem to='/chat' title='Chat' fontIcon='bi-chat-left' icon='message-text-2' />
    </>
  )
}

export {SidebarMenuMain}
