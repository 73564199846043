type Item = Record<string, any>

const useStep = (item: Item = {}) => {
  const statusRequest = localStorage.getItem('STATUS-REQUEST')
  if (Object.keys(item).length === 0 && statusRequest != null) {
    return JSON.parse(statusRequest) as Item
  } else {
    localStorage.setItem('STATUS-REQUEST', JSON.stringify(item))
    return item
  }
}

const useCommentDrawer = (item: Item = {}) => {
  const commentDrawer = localStorage.getItem('COMMENT-DRAWER')

  if (Object.keys(item).length === 0 && commentDrawer != null) {
    return JSON.parse(commentDrawer) as Item
  } else {
    localStorage.setItem('COMMENT-DRAWER', JSON.stringify(item))
    return item
  }
}

export {useStep, useCommentDrawer}
