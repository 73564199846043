import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_elements/helpers'
import Axios from '../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/admin/users/`

const userData =  async(id) => {
  return Axios
    .get(`${GET_USERS_URL}${id}`)
}

export {userData}