import Axios from '../../../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const PAYMENT = `${API_URL}/step/get/payment`
export const PAYMENT_SEND = `${API_URL}/step/update/payment`
export const PAYMENT_SEND_UPLOAD = `${API_URL}/step/update/payment-upload`
export const PAYMENT_SEND_DELETE = `${API_URL}/step/update/payment-delete-upload`

export function GetData() {
  return Axios.post(PAYMENT)
}

export function SendData(data) {
  return Axios.post(PAYMENT_SEND, data)
}
export function Upload(data) {
  return Axios.post(PAYMENT_SEND_UPLOAD, data)
}
export function DeleteUpload(data) {
  return Axios.post(PAYMENT_SEND_DELETE, data)
}
