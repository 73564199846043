import axios, {AxiosResponse} from 'axios'
import Axios from '../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_THEME_API_URL

const GET_USERS_URL = `${API_URL}/admin/dashboard/users`
const GET_REQUESTS_URL = `${API_URL}/admin/dashboard/requests`

const getUsers = () => {
  return Axios.get(`${GET_USERS_URL}`).then((d) => d.data)
}

const getRequests = () => {
  return Axios.get(`${GET_REQUESTS_URL}`).then((d) => d.data)
}

export {getUsers, getRequests}
