import {useEffect, useState} from 'react'
import {checkStep, verifyEmail} from './requests/_requests'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../../../modules/auth'
import clsx from 'clsx'
import Comments from './components/Comments'
import Check from './components/Check'

const VerifyEmail = () => {
  const [personalData, setPersonalData] = useState([])
  const [comment, setComment] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const auth = useAuth()

  useEffect(() => {
    verifyEmail(params.id).then((response) => {
      setPersonalData(response.data?.['data'])
      setComment(response.data?.['comments'])
      setLoading(false)
    })
  }, [])

  return (
    <div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
          <div className='align-self-center'>
            <Check id={params.id} step={1} key={1} />
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Given Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{auth.currentUser?.['email']}</span>
            </div>
          </div>
        </div>
      </div>
      <Comments comment={comment} step={1} />
    </div>
  )
}
export default VerifyEmail
