import {useState, useEffect} from 'react'
import pusherJs from 'pusher-js'
import {useTokenContext} from '../app/Context/TokenContext'
import Pusher from 'pusher-js/types/src/core/pusher'

export const MainPusher = (token) => {

  const options = {
    broadcaster: 'pusher',
    key: '182cf3b8c8c23bbd5e05',
    cluster: 'eu',
    authEndpoint: 'https://back-123study.otters.ir/api/auth/verify-token-check',
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      encrypted: true,
      forceTLS: true,
    },
  }

  const pusher = new pusherJs('182cf3b8c8c23bbd5e05', options)


  return pusher
}
