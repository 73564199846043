// GiveFish.jsx

import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../_elements/helpers'
import './GiveFish.css' // Import the CSS file
import {Button, Modal} from 'react-bootstrap'
import {checkFish} from '../../requests/_requests'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../../../../modules/auth'

const GiveFish = ({fish, id, updateData, paymentEnd}) => {
  const [photo, setPhoto] = useState('')
  const [accept, setAccept] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const auth = useAuth()
  useEffect(() => {
    setAccept(paymentEnd)
  },[])
  const check = async (ch) => {
    await checkFish({check: ch}, id).then((res) => {
      if (res.data.status_payment == 5) {
        setAccept(true)
      }
      updateData(res.data.status_payment > 3 ? true : false)
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        icon: 'success',
        title: 'Your work has been saved',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        confirmButtonColor: 'rgb(70, 196, 127)',
      })
    })
  }
  return (
    <div>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>
      <div className='card-body'>
        {accept ? (
          <div className='alert alert-primary d-flex align-items-center p-5'>
            <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-dark'>This is an alert</h4>

              <span>
                The alert component can be used to highlight certain parts of your page for higher
                content visibility.
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='row'>
          <div className='row col-6 m-3'>
            {fish.map((val, index) => {
              let format = val.toString().split('.', 2)[1]

              return (
                <div
                  key={index}
                  className={`fish-card m-3`}
                  style={{
                    backgroundImage: `url("${toAbsoluteUrl(`/media/svg/files/${format}.png`)}")`,
                  }}
                >
                  <div className='button-container'>
                    <button
                      className='btn btn-primary d-inline-block w-25 m-1'
                      style={{padding: 10}}
                    >
                      <i className='bi bi-bookmarks-fill fs-2x'></i>
                    </button>
                    {['jpeg', 'jpg', 'png'].includes(format) ? (
                      <button
                        className='btn btn-secondary d-inline-block w-25 m-1'
                        style={{padding: 10}}
                        onClick={() => {
                          handleShow()
                          setPhoto(val)
                        }}
                      >
                        <i className='bi bi-bookmarks-fill fs-2x'></i>
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className='col-5'>
            <p>
              It seems like you might be referring to the height of an element in Bootstrap. In
              Bootstrap, the height of an element is typically controlled by the content within it
              or by using utility classes. It seems like you might be referring to the height of an
              element in Bootstrap. In Bootstrap, the height of an element is typically controlled
              by the content within it or by using utility classes. It seems like you might be
              referring to the height of an element in Bootstrap. In Bootstrap, the height of an
              element is typically controlled by the content within it or by using utility classes.
            </p>
            <button className='btn btn-danger m-2' onClick={() => check(false)}>
              Reject
            </button>
            <button className='btn btn-primary m-2' onClick={() => check(true)}>
              Accept
            </button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} dialogClassName=' modal-lg'>
        <Modal.Body>
          <div className='d-flex justify-content-center align-items-center'>
            <img src={photo} alt='Description' />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {GiveFish}
