import Apply from './steps/Apply'
import Document from './steps/Document'
import Education from './steps/Education'
import Language from './steps/Language'
import Payment from './steps/Payemnt'
import Personal from './steps/Personal'
import VerifyEmail from './steps/VerifyEmail'

const Step = ({step_number,next,back}) => {
  switch (step_number) {
    case 1:
      return <VerifyEmail step_number={step_number} next={next} back={back}/>
    case 2:
      return <Personal step_number={step_number} next={next} back={back}/>
    case 3:
      return <Education step_number={step_number} next={next} back={back}/>
    case 4:
      return <Language  step_number={step_number} next={next} back={back}/>
    case 5:
      return <Document step_number={step_number} next={next} back={back}/>
    case 6:
      return <Apply step_number={step_number} next={next} back={back}/>
    case 7:
      return <Payment step_number={step_number} next={next} back={back}/>
    default:
      return <div>

      </div>
  }
}
export default Step
