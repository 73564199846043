import axios, {AxiosResponse} from 'axios'
import Axios from '../../../../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SEND_MESSAGE = `${API_URL}/admin/comment/send`



const send = async (item) => {
  return Axios.post(`${SEND_MESSAGE}`,item)
}

export {send}
