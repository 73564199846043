import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_elements/helpers'
import Axios from '../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_CHAT = `${API_URL}/admin/chat`
const SEND_MESSAGE = `${API_URL}/admin/send`
const SEEN_MESSAGE = `${API_URL}/admin/seen`

const chats = async (input='') => {
  return Axios.get(`${GET_CHAT}?search=${input}`)
}

const send = async (item) => {
  return Axios.post(`${SEND_MESSAGE}`,item)
}

const seen = async (item) => {
  return Axios.patch(`${SEEN_MESSAGE}`, item)
}


export {chats, send, seen}
