import {useContext, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../../_elements/helpers'
import Comments from './components/Comments'
import {
  closePayment,
  getPayment,
  payment as paymentUpdate,
  openPayment,
  checkFish,
} from './requests/_requests'
import {useParams} from 'react-router-dom'
import {response} from 'express'
import UserContext from '../UserContext'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import clsx from 'clsx'
import {PaymentValidation, SchoolValidation} from './components/Validation'
import Modal from 'react-bootstrap/Modal'
import {GiveFish} from './components/payment/GiveFish'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ApplyStepAdminLoading from '../../../../../../_elements/loading-skeleton/ApplyStepAdminLoading'

const Payment = () => {
  const [payment, setPayment] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [school, setSchool] = useState<
    {schoolName: string; priceAF: string; price: string; link: string; description: string}[]
  >([])
  const [initValueModal, setInitValueModal] = useState({
    schoolName: '',
    priceAF: '',
    price: '',
    link: '',
    description: '',
  })
  const [initValue, setInitValue] = useState({
    item_name: '',
    name_card: '',
    notes: '',
    bank_name: '',
    number_card: '',
  })
  const params = useParams()
  const [comment, setComment] = useState([])
  const [modal, setModal] = useState(false)
  const user = useContext(UserContext)['user']

  useEffect(() => {
    getPayment(params.id).then((response) => {
      if (response.data?.['payment_data']) {
        setInitValue(response.data?.['payment_data'])
        setSchool(JSON.parse(response.data?.['payment_data']['schools']))
      }
      setPaymentStatus(response.data?.['end_payment'])
      setPayment(response.data?.['payment_status'])
      setComment(response.data?.['comments'])

      setLoading(false)
    })
  }, [])

  const on = () => {
    openPayment(params.id)
      .then((response) => {
        setPayment(true)
        setError(false)
      })
      .catch((err) => {
        if (err && err.response && err.response.status == 422) {
          setError(err.response.data.message)
        }
      })
  }
  const off = () => {
    closePayment(params.id).then((response) => setPayment(false))
  }
  const onSubmitModal = (item) => {
    setModal(false)
    setSchool([...school, item])
  }
  const onSubmit = async (values) => {
    let item = values
    item.id = user.id
    item['school'] = school
    await paymentUpdate(item).then((response) => {
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        icon: 'success',
        title: 'Your work has been saved',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        confirmButtonColor: 'rgb(70, 196, 127)',
      })
    })
  }

  const updateData = (item) => {
    setPaymentStatus(item)
  }
  const check = async (ch) => {
    await checkFish({check: ch}, params.id).then((res) =>
      updateData(res.data.status_payment > 3 ? true : false)
    )
  }
  if (loading) {
    return <ApplyStepAdminLoading />
  }
  return (
    <>
      <div className='card m-3'>
        {paymentStatus ? (
          <GiveFish
            fish={initValue?.['fish'] ? JSON.parse(initValue?.['fish']) : []}
            id={params.id}
            updateData={updateData}
            paymentEnd={paymentStatus}
          />
        ) : (
          <div className='card-body p-12'>
            <Formik
              initialValues={initValue}
              validationSchema={PaymentValidation}
              onSubmit={onSubmit}
            >
              {({errors, touched}) => {
                return (
                  <Form id='form_payment'>
                    <div className='d-flex flex-column align-items-start flex-xxl-row'>
                      <div className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'>
                        <span className='fs-2x fw-bold text-gray-800'>Invoice #</span>
                        <input
                          className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                          placeholder='...'
                          type='text'
                          value='2021001'
                        />
                      </div>
                      <div className='card-toolbar'></div>
                    </div>

                    <div className='separator separator-dashed my-10'></div>
                    {error && (
                      <div className='alert alert-dismissible bg-light-danger border border-danger d-flex flex-column flex-sm-row p-5 mb-10'>
                        <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                          ...
                        </span>
                        <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
                          <span>{error}</span>
                        </div>

                        <button
                          type='button'
                          className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                          data-bs-dismiss='alert'
                        >
                          <span className='svg-icon svg-icon-2x svg-icon-light'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className='mh-50px'
                            >
                              <rect
                                opacity='0.5'
                                x='6'
                                y='17.3137'
                                width='16'
                                height='2'
                                rx='1'
                                transform='rotate(-45 6 17.3137)'
                                fill='black'
                              ></rect>
                              <rect
                                x='7.41422'
                                y='6'
                                width='16'
                                height='2'
                                rx='1'
                                transform='rotate(45 7.41422 6)'
                                fill='black'
                              ></rect>
                            </svg>
                          </span>
                        </button>
                      </div>
                    )}
                    <div className='mb-0'>
                      <div className='row gx-10 mb-5'>
                        <div className='col-lg-12'>
                          <button
                            className='btn btn-light-primary'
                            onClick={() => setModal(true)}
                            type='button'
                          >
                            Add School
                          </button>
                          <table className='table align-middle table-row-dashed gy-5'>
                            <thead>
                              <tr>
                                <th className='fw-bolder text-gray-500'>Name</th>
                                <th className='fw-bolder text-gray-500'>Price</th>
                                <th className='fw-bolder text-gray-500'>Price AF</th>
                                <th className='fw-bolder text-gray-500'>Link</th>
                                <th>...</th>
                              </tr>
                            </thead>
                            {school.length != 0 ? (
                              <tbody className='fs-6 fw-semibold text-gray-600'>
                                {school.map((value) => (
                                  <tr>
                                    <td>{value.schoolName}</td>
                                    <td>{value.price}</td>
                                    <td>{value.priceAF}</td>
                                    <td>{value.link}</td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          setSchool(school.filter((item) => value != item))
                                        }
                                        className='btn btn-light-danger bi bi-eraser-fill'
                                        type='button'
                                      ></button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              ''
                            )}
                          </table>
                          {school.length == 0 && (
                            <h2 style={{textAlign: 'center', color: 'gray', margin: 25}}>
                              Not found school
                            </h2>
                          )}
                          <hr className='w-100 border-gray-400 border-2' />
                          <table className='table align-middle table-row-dashed gy-5'>
                            <tbody className='fs-6 fw-semibold text-gray-600'>
                              <tr>
                                <td>Username</td>
                                <td>{user.username}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{user.email}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className='table-responsive mb-10'>
                      <table className='table g-5 gs-0 mb-0 fw-bold text-gray-700'>
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-300px w-475px'>Item</th>
                            <th className='min-w-150px w-150px'>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='border-bottom border-bottom-dashed'>
                            <td className='pe-7'>
                              <Field
                                className={clsx(
                                  'form-control form-control-solid  mb-2',
                                  {'is-invalid': touched.item_name && errors.item_name},
                                  {
                                    'is-valid': touched.item_name && !errors.item_name,
                                  }
                                )}
                                name='item_name'
                                id='item_name'
                                placeholder='Item name'
                                type='text'
                              />

                              <Field
                                className={clsx(
                                  'form-control form-control-solid',
                                  {'is-invalid': touched.number_card && errors.number_card},
                                  {
                                    'is-valid': touched.number_card && !errors.number_card,
                                  }
                                )}
                                name='number_card'
                                id='number_card'
                                placeholder='Number Card'
                                type='text'
                              />
                            </td>
                            <td className='ps-0 '>
                              <Field
                                className={clsx(
                                  'form-control form-control-solid mt-2',
                                  {'is-invalid': touched.name_card && errors.name_card},
                                  {
                                    'is-valid': touched.name_card && !errors.name_card,
                                  }
                                )}
                                id='name_card'
                                name='name_card'
                                placeholder='Name Card'
                                type='text'
                              />
                              <Field
                                className={clsx(
                                  'form-control form-control-solid mt-2',
                                  {'is-invalid': touched.bank_name && errors.bank_name},
                                  {
                                    'is-valid': touched.bank_name && !errors.bank_name,
                                  }
                                )}
                                id='bank_name'
                                name='bank_name'
                                placeholder='Bank name'
                                type='text'
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <Field
                        className={clsx(
                          'form-control form-control-solid ',
                          {'is-invalid': touched.notes && errors.notes},
                          {
                            'is-valid': touched.notes && !errors.notes,
                          }
                        )}
                        id='notes'
                        name='notes'
                        placeholder='notes'
                        type='text'
                      />
                    </div>
                    <div className='m-3'>
                      {payment ? (
                        <div>
                          <button className='btn btn-danger' type='button' onClick={() => off()}>
                            <i className='bi bi-x fs-3'></i>Off
                          </button>
                          <button className='btn btn-primary'>Submit</button>
                        </div>
                      ) : (
                        <button className='btn btn-success mt-3 ' onClick={() => on()}>
                          <i className='bi bi-check2 fs-3'></i> On
                        </button>
                      )}
                      <button
                        className='btn btn-primary '
                        type='button'
                        style={{float: 'right'}}
                        onClick={() => check(true)}
                      >
                        On Check fish
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        )}
      </div>

      <Modal
        size='lg'
        show={modal}
        onHide={() => setModal(false)}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-custom-modal-styling-title'>Custom Modal Styling</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initValueModal}
          validationSchema={SchoolValidation}
          onSubmit={onSubmitModal}
        >
          {({errors, touched}) => (
            <Form id='form_school'>
              <Modal.Body>
                <div className='row'>
                  <div className='col-lg-6 col-12 fv-row mb-7 fv-plugins-icon-container'>
                    <label className='required fs-6 fw-semibold mb-2'>School Name:</label>
                    <Field
                      className={clsx(
                        'form-control form-control-solid ',
                        {'is-invalid': touched.schoolName && errors.schoolName},
                        {
                          'is-valid': touched.schoolName && !errors.schoolName,
                        }
                      )}
                      name='schoolName'
                      id='schoolName'
                      placeholder='Write Your School Name'
                      type='text'
                    />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='schoolName' className='text-danger' />
                    </div>
                  </div>
                  <div className='col-lg-6 col-12 fv-row mb-7 fv-plugins-icon-container'>
                    <label className='required fs-6 fw-semibold mb-2'>priceAF:</label>
                    <Field
                      className={clsx(
                        'form-control form-control-solid ',
                        {'is-invalid': touched.priceAF && errors.priceAF},
                        {
                          'is-valid': touched.priceAF && !errors.priceAF,
                        }
                      )}
                      name='priceAF'
                      id='priceAF'
                      placeholder='Write Your Field Study'
                      type='text'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='priceAF' className='text-danger' />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-12 fv-row mb-7 fv-plugins-icon-container'>
                    <label className='required fs-6 fw-semibold mb-2'>price:</label>
                    <Field
                      className={clsx(
                        'form-control form-control-solid ',
                        {'is-invalid': touched.price && errors.price},
                        {
                          'is-valid': touched.price && !errors.price,
                        }
                      )}
                      name='price'
                      id='price'
                      placeholder='Write Your School Name'
                      type='text'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='price' className='text-danger' />
                    </div>
                  </div>
                  <div className='col-lg-6 col-12 fv-row mb-7 fv-plugins-icon-container'>
                    <label className='required fs-6 fw-semibold mb-2'>Link:</label>
                    <Field
                      className={clsx(
                        'form-control form-control-solid ',
                        {'is-invalid': touched.link && errors.link},
                        {
                          'is-valid': touched.link && !errors.link,
                        }
                      )}
                      id='link'
                      name='link'
                      placeholder='Write Your Field Study'
                      type='text'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='link' className='text-danger' />
                    </div>
                  </div>
                  <div className='col-lg-12 col-12 fv-row mb-7 fv-plugins-icon-container'>
                    <label className='required fs-6 fw-semibold mb-2'>Description:</label>
                    <Field
                      className={clsx(
                        'form-control form-control-solid ',
                        {'is-invalid': touched.description && errors.description},
                        {
                          'is-valid': touched.description && !errors.description,
                        }
                      )}
                      id='description'
                      name='description'
                      placeholder='Write Your Field Study'
                      type='text'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='description' className='text-danger' />
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <div className='modal-footer'>
                <button className='btn btn-light' onClick={() => setModal(false)} type='button'>
                  Close
                </button>
                <button className='btn btn-light-primary'>Add</button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <Comments comment={comment} step={7} />
    </>
  )
}
export default Payment
