// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {DateCell} from './DateCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import {RuleCell} from './RuleCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    id: 'rule',
    Cell: ({...props}) => <RuleCell  rule={props.data[props.row.index].rule}/>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Email verify at' className='min-w-125px' />
    ),
    id: 'email_verified_at',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].email_verified_at} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Update at' className='min-w-125px' />
    ),
    id: 'update_at',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].updated_at} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Register at' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
