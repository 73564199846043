import {ErrorMessage, Field, Formik, Form} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../../../_elements/helpers'
import {PersonalValidation} from './core/validation/Validation'
import {
  GetData,
  GetCountry,
  SendData,
  UploadProfile,
  DeleteUploadProfile,
} from './core/Requests/__requests_personal'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import ImageCropper from './core/components/ImageCropper'
import {date} from 'yup'
import CropAndUploadFile from './core/components/CropAndUploadFile'
import ApplyStepUserLoading from '../../../../../_elements/loading-skeleton/ApplyStepUserLoading'

const Personal = ({step_number, next, back}) => {
  const [loading, setLoading] = useState(true)
  const [profileDefault, setProfileDefault] = useState<string>()
  const [profile, setProfile] = useState<Blob>()
  const [inits, setInits] = useState({
    phone: '',
    mailing: '',
    emergency: '',
    address: '',
    given_name: '',
    family_name: '',
    middle_name: '',
    birth_date: '',
    passport_number: '',
    passport_expire: '',
    country_citizenship: 'Canada',
    gender: 'male',
    profile: '',
  })
  const [country, setCountry] = useState([])
  useEffect(() => {
    const requests = [GetData(), GetCountry()]
    Promise.all(requests)
      .then((responses) => {
        if (responses?.[0]['data']['data'] != null) {
          setInits(responses?.[0]['data']['data'])
          setProfileDefault(responses?.[0]['data']['data']['profile'])
        }
        setLoading(false)
        const countryData = responses?.[1]?.data
        if (countryData != null) {
          setCountry(countryData)
        }
      })
      .catch((error) => {})
  }, [])

  const submitRequest = async (value) => {
    return SendData({...value, file: profile})
      .then((response) => {
        next(response.data.status.status_request)
      })
      .catch((errors) => {})
  }

  if (loading) {
    return <ApplyStepUserLoading />
  }
  return (
    <div>
      <Formik validationSchema={PersonalValidation} initialValues={inits} onSubmit={submitRequest}>
        {({errors, touched}) => {
          return (
            <Form className='py-20 w-100 w-xl-700px px-9' id='kt_create_account_form'>
              <div className='w-100'>
                <div className='pb-10 pb-lg-15'>
                  <h2 className='fw-bolder text-dark'>Personal</h2>

                  <div className='text-gray-400 fw-bold fs-6'>If you need more change email.</div>
                </div>
                {/* phone number */}
                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label  required mb-3'>
                    Write your phone number
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.phone && errors.phone},
                          {
                            'is-valid': touched.phone && !errors.phone,
                          }
                        )}
                        name='phone'
                        id='phone'
                        placeholder='Your phone'
                        type='number'
                      />

                      <div className='text-danger mt-2'>
                        <ErrorMessage name='phone' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label  required mb-3'>
                    Select your profile
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <CropAndUploadFile
                        data={(img) => {
                          setProfile(img.fileB)
                        }}
                        defaultData={profileDefault}
                      />
                    </div>
                  </div>
                </div>
                {/* name and family */}
                <div className='mb-10 fv-row'>
                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col-6'>
                      <label className='d-flex align-items-center form-label  required mb-3'>
                        Write given name
                      </label>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.given_name && errors.given_name},
                          {
                            'is-valid': touched.given_name && !errors.given_name,
                          }
                        )}
                        name='given_name'
                        id='given_name'
                        placeholder='your name'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='given_name' className='text-danger' />
                      </div>
                    </div>
                    <div className='col-6'>
                      <label className='d-flex align-items-center form-label  required mb-3'>
                        Write your family name
                      </label>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.family_name && errors.family_name},
                          {
                            'is-valid': touched.family_name && !errors.family_name,
                          }
                        )}
                        name='family_name'
                        placeholder='your family name'
                        id='family_name'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='family_name' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                {/* middle name */}
                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label mb-3'>
                    Write your middle name
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.middle_name && errors.middle_name},
                          {
                            'is-valid': touched.middle_name && !errors.middle_name,
                          }
                        )}
                        name='middle_name'
                        id='middle_name'
                        placeholder='your middle name'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='middle_name' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                {/* address */}
                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label  required mb-3'>
                    Write your address
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.address && errors.address},
                          {
                            'is-valid': touched.address && !errors.address,
                          }
                        )}
                        name='address'
                        id='address'
                        placeholder='your address'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='address' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                {/* emergency and mailing */}
                <div className='mb-10 fv-row'>
                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col-6'>
                      <label className='d-flex align-items-center form-label  required mb-3'>
                        Write your mailing
                      </label>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.mailing && errors.mailing},
                          {
                            'is-valid': touched.mailing && !errors.mailing,
                          }
                        )}
                        name='mailing'
                        id='mailing'
                        placeholder='your mailing'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='mailing' className='text-danger' />
                      </div>
                    </div>
                    <div className='col-6'>
                      <label className='d-flex align-items-center form-label  required mb-3'>
                        Write your emergency
                      </label>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.emergency && errors.emergency},
                          {
                            'is-valid': touched.emergency && !errors.emergency,
                          }
                        )}
                        name='emergency'
                        id='emergency'
                        type='number'
                        placeholder='your emergency'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='emergency' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                {/* birth date */}
                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label  required mb-3'>
                    Select your birth date
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <div className='position-relative d-flex align-items-center '>
                        <span className='svg-icon svg-icon-2 position-absolute mx-4'>
                          <svg
                            fill='none'
                            height='24'
                            viewBox='0 0 24 24'
                            width='24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z'
                              fill='black'
                              opacity='0.3'
                            ></path>
                            <path
                              d='M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z'
                              fill='black'
                            ></path>
                            <path
                              d='M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z'
                              fill='black'
                            ></path>
                          </svg>
                        </span>
                        <Field
                          className={clsx(
                            'form-control ',
                            {'is-invalid': touched.birth_date && errors.birth_date},
                            {
                              'is-valid': touched.birth_date && !errors.birth_date,
                            }
                          )}
                          name='birth_date'
                          id='birth_date'
                          style={{paddingLeft: 35}}
                          placeholder='Pick date range'
                          type='date'
                        />
                      </div>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='birth_date' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                {/* passpor number and passport expire */}
                <div className='mb-10 fv-row'>
                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col-6'>
                      <label className='d-flex align-items-center form-label  required mb-3'>
                        Write passport number
                      </label>
                      <Field
                        className={clsx(
                          'form-control ',
                          {'is-invalid': touched.passport_number && errors.passport_number},
                          {
                            'is-valid': touched.passport_number && !errors.passport_number,
                          }
                        )}
                        name='passport_number'
                        id='passport_number'
                        placeholder='your passport number'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='passport_number' className='text-danger' />
                      </div>
                    </div>
                    <div className='col-6'>
                      <label className='d-flex align-items-center form-label  required mb-3'>
                        Select your passport expire
                      </label>
                      <div className='position-relative d-flex align-items-center '>
                        <span className='svg-icon svg-icon-2 position-absolute mx-4'>
                          <svg
                            fill='none'
                            height='24'
                            viewBox='0 0 24 24'
                            width='24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z'
                              fill='black'
                              opacity='0.3'
                            ></path>
                            <path
                              d='M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z'
                              fill='black'
                            ></path>
                            <path
                              d='M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z'
                              fill='black'
                            ></path>
                          </svg>
                        </span>
                        <Field
                          className={clsx(
                            'form-control ',
                            {'is-invalid': touched.passport_expire && errors.passport_expire},
                            {
                              'is-valid': touched.passport_expire && !errors.passport_expire,
                            }
                          )}
                          name='passport_expire'
                          id='passport_expire'
                          style={{paddingLeft: 35}}
                          placeholder='Pick date range'
                          type='date'
                        />
                      </div>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='passport_expire' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label  required mb-3'>
                    Select country
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <Field
                        className={clsx(
                          'form-select ',
                          {'is-invalid': touched.country_citizenship && errors.country_citizenship},
                          {
                            'is-valid': touched.country_citizenship && !errors.country_citizenship,
                          }
                        )}
                        name='country_citizenship'
                        id='country_citizenship'
                        as='select'
                      >
                        {country != null ? (
                          country.map((val) => {
                            return (
                              <option key={val?.['id']} value={val?.['name']}>
                                {val?.['name']}
                              </option>
                            )
                          })
                        ) : (
                          <option></option>
                        )}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='country_citizenship' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>

                {/* gender */}
                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center form-label  required mb-3'>
                    Select your gender
                  </label>

                  <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                      <div
                        className={`form-check form-check-custom ${
                          errors.gender ? 'form-check-danger' : 'form-check-solid'
                        } m-2`}
                      >
                        <Field
                          className='form-check-input'
                          type='radio'
                          value='male'
                          name='gender'
                          id='gender'
                        />
                        <label className='form-check-label' htmlFor='gender'>
                          Male
                        </label>
                      </div>
                      <div
                        className={`form-check form-check-custom ${
                          errors.gender ? 'form-check-danger' : 'form-check-solid'
                        } m-2`}
                      >
                        <Field
                          className='form-check-input'
                          type='radio'
                          value='female'
                          name='gender'
                          id='gender'
                        />
                        <label className='form-check-label' htmlFor='gender'>
                          Female
                        </label>
                      </div>
                      <div
                        className={`form-check form-check-custom ${
                          errors.gender ? 'form-check-danger' : 'form-check-solid'
                        } m-2`}
                      >
                        <Field
                          className='form-check-input'
                          type='radio'
                          value='other'
                          name='gender'
                          id='gender'
                        />
                        <label className='form-check-label' htmlFor='gender'>
                          Other
                        </label>
                      </div>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='given_name' className='text-danger' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={() => back()}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      Submit and Next
                      <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default Personal
