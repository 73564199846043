/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_elements/helpers'
import {Dropdown1} from '../../../../../_elements/partials'
import {ChatInner} from './chat/ChatInner'
import {chats as chatRequest, seen} from './_requests'
import ChatLoading from '../../../../../_elements/loading-skeleton/ChatLoading'
import {response} from 'express'

const Private: FC = () => {
  const [users, setUsers] = useState([])
  const [chat, setChat] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    chatRequest().then((response) => {
      setUsers(response.data)
      seen({id: response.data[0].id}).then((res) => setUsers(res.data))
      setChat(response.data[0])
      setLoading(false)
    })
  }, [])

  const search = (input) => {
    chatRequest(input).then((response) => {
      setUsers(response.data)
    })
  }

  const clickChat = async (value) => {
    setChat(value)
    await seen({id: value.id}).then((response) => setUsers(response.data))
  }

  if (loading) {
    return <ChatLoading />
  }
  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <form className='w-100 position-relative' autoComplete='off'>
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                onChange={(event) => {
                  search(event.target.value)
                }}
                placeholder='Search by username or email...'
              />
            </form>
          </div>

          <div className='card-body pt-5' id='kt_chat_contacts_body'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
              style={{maxHeight: 678}}
            >
              {users.map((value) => {
                return (
                  <div>
                    <div className='d-flex flex-stack py-4'>
                      <div className='d-flex align-items-center'>
                        <div
                          className='symbol symbol-45px symbol-circle'
                          style={{
                            backgroundImage: `url("${value['profile']}")`,
                            width: 50,
                            height: 50,
                            backgroundSize: 'cover',
                          }}
                        >
                          {/* <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                            M
                          </span> */}
                          {/* <div style={{'backgroundImage':`url("${value['profile']}")`}}></div> */}
                        </div>

                        <div className='ms-5'>
                          <a
                            onClick={() => clickChat(value)}
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {value['username']}
                          </a>
                          <div className='fw-bold text-gray-400'>{value['email']}</div>
                        </div>
                      </div>
                      {value['seen'] !== 0 ? (
                        <div className='d-flex flex-column align-items-end ms-2'>
                          <span
                            className={`badge badge-sm badge-circle badge-light-${
                              value['seen'] >= 5 && value['seen'] < 10
                                ? 'warning'
                                : value['seen'] >= 10
                                ? 'danger'
                                : 'success'
                            }`}
                          >
                            {value['seen']}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className='separator separator-dashed d-none'></div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-toolbar'>
              <div className='me-n3'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-2'></i>
                </button>
                <Dropdown1 />
              </div>
            </div>
          </div>
          {chat ? <ChatInner chat={chat} /> : ''}
        </div>
      </div>
    </div>
  )
}

export {Private}
