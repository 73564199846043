import Axios from '../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const MENU_APPLY = `${API_URL}/menu-apply`


export function getMenus() {

  return Axios.get<{result: boolean}>(MENU_APPLY)
}
