/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_elements/helpers'
import {Card4} from '../../../../../../_elements/partials/content/cards/Card4'
import {checkStep, documents} from './requests/_requests'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import Comments from './components/Comments'
import Check from './components/Check'

export function Documents() {
  const [files, setFiles] = useState([])
  const [data, setData] = useState([])
  const params = useParams()
  const [comment, setComment] = useState([])

  useEffect(() => {
    documents(params.id).then((response) => {
      setData(response.data.data)
      setComment(response.data?.['comments'])

    })
  }, [])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          My Documents
          <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span>
        </h3>

        <div className='d-flex my-2'>
          {/* <div className='d-flex align-items-center position-relative me-4'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Search'
            />
          </div> */}
          <div className='align-self-center'>

            <Check id={params.id} step={5} key={5} />
          </div>
        </div>
      </div>

      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        <div
          onClick={() => setFiles(JSON.parse(data['passport']))}
          className='col-12 col-sm-12 col-xl'
        >
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Passport'
            description='7 files'
          />
        </div>
        <div
          onClick={() => setFiles(JSON.parse(data['national_id']))}
          className='col-12 col-sm-12 col-xl'
        >
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='National id'
            description='7 files'
          />
        </div>
        <div
          onClick={() => setFiles(JSON.parse(data['education']))}
          className='col-12 col-sm-12 col-xl'
        >
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Education'
            description='7 files'
          />
        </div>
        <div
          onClick={() => setFiles(JSON.parse(data['language']))}
          className='col-12 col-sm-12 col-xl'
        >
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Language'
            description='7 files'
          />
        </div>
        <div onClick={() => setFiles(JSON.parse(data['CV']))} className='col-12 col-sm-12 col-xl'>
          <Card4 icon='/media/svg/files/folder-document.svg' title='CV' description='7 files' />
        </div>
        <div onClick={() => setFiles(JSON.parse(data['GRE']))} className='col-12 col-sm-12 col-xl'>
          <Card4 icon='/media/svg/files/folder-document.svg' title='GRE' description='3 files' />
        </div>
        <div
          onClick={() => setFiles(JSON.parse(data['other']))}
          className='col-12 col-sm-12 col-xl'
        >
          <Card4 icon='/media/svg/files/folder-document.svg' title='Other' description='3 files' />
        </div>
      </div>

      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        {files.map((value) => {
          let format = String(value).split('.', 2)[1]
          let nameFile = String(value).split('/')
          return (
            <div className='col-12 col-sm-12 col-xl'>
              <Card4
                icon={`/media/svg/files/${format}.png`}
                title={nameFile[nameFile.length - 1]}
                description='3 days ago'
              />
            </div>
          )
        })}
      </div>
      <Comments comment={comment} step={5} />
    </>
  )
}
