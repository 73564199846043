import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const MenuUserLoading = () => {
  return (
    <div>

      <div

        className=' d-flex flex-column flex-xl-row flex-row-fluid'

      >
        {/* begin::Aside*/}
        <div className=' w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>

            </div>
              <Skeleton height={500} width={'100%'} />
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <div className='py-20 w-100 w-xl-700px px-9'>
            <div className='w-100'>
              <div className='mb-10 fv-row'>
                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col-6'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                  <div className='col-6'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                </div>
              </div>

              <div className='mb-10 fv-row'>
                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col-6'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                  <div className='col-6'>
                    <Skeleton height={50} width={'100%'} />
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-10 fv-row'>
              <div className='row mb-2' data-kt-buttons='true'>
                <div className='col-6'>
                  <Skeleton height={50} width={'30%'} style={{float: 'left'}} />
                </div>
                <div className='col-6'>
                  <Skeleton height={50} width={'40%'} style={{float: 'right'}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuUserLoading
