import axios from 'axios'
import Axios from '../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const CHAT = `${API_URL}/chat`
export const SEND = `${API_URL}/send`

// Server should return AuthModel
export function getMessage() {
  return Axios.get(CHAT)
}
export function send(message) {
  return Axios.post(SEND, {message: message})
}