import React, {createContext, useState, ReactNode, useContext} from 'react'
import {useAuth} from '../../../modules/auth'

interface UserContextProps {
  data
  updateUser: (dataUser) => void
}

const initialData = {}

const UserContext = createContext<UserContextProps>({
  data: initialData,
  updateUser: () => {},
})

interface ContextProviderProps {
  children: ReactNode
}

const UserContextProvider = ({children}: ContextProviderProps) => {
  const {currentUser} = useAuth()
  const [data, setData] = useState(currentUser)

  const updateUser = (dataUser) => {
    setData(dataUser)
  }

  return <UserContext.Provider value={{data, updateUser}}>{children}</UserContext.Provider>
}

const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }

  return context
}

export {UserContext, UserContextProvider, useUserContext}
