import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../../_elements/helpers'
import {useEffect, useState} from 'react'
import {personal, checkStep} from './requests/_requests'
import clsx from 'clsx'
import Comments from './components/Comments'
import Check  from './components/Check'
import ApplyStepAdminLoading from '../../../../../../_elements/loading-skeleton/ApplyStepAdminLoading'

const Personal = () => {
  const [personalData, setPersonalData] = useState([])
  const [comment, setComment] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()

  useEffect(() => {
    personal(params.id).then((response) => {
      setPersonalData(response.data?.['data'])
      setComment(response.data?.['comments'])
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <ApplyStepAdminLoading />
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>

          <Check id={params.id} step={2} key={2} />
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Given Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{personalData?.['given_name']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Middle name</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['middle_name']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Family name</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['family_name']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Address</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['address']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Emergency</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['emergency']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Mailing</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['mailing']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Phone</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['phone']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Birth date</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['birth_date']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Passport number</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['passport_number']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Passport expire</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['passport_expire']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Country citizenship</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['country_citizenship']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Gender</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{personalData['gender']}</span>
            </div>
          </div>
        </div>
      </div>
      <Comments comment={comment} step={2}/>
    </>
  )
}
export default Personal
