/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../../helpers'
import {getMessage, send} from './_requests'
import {useAuth} from '../../../../app/modules/auth'
import pusherJs from 'pusher-js'
import {useStep} from '../../../../app/pages/user/dashboard/Hooks'
import {MainPusher} from '../../../../_requests/_pusher_main'
import {Toast} from 'react-bootstrap'
import {useTokenContext} from '../../../../app/Context/TokenContext'

const bufferMessages = defaultMessages

const CommentInner = ({isDrawer = false, toast}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModel[]>([])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [loading, setLoading] = useState(true)
  const [loadingSend, setLoadingSend] = useState<boolean>(false)
  const step = useStep()
  const auth = useAuth()

  useEffect(() => {
    getMessage(step.status)
      .then((res) => {
        setMessages(res.data)
        setLoading(false)
      })
      .catch()
  }, [])
  const sendMessage = () => {
    setLoadingSend(true)
    send(message, step.status).then((response) => {
      setLoadingSend(false)
      setMessages((prevMessages) => [...prevMessages, response.data])
      setMessage('')
    })
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const [drawerState] = useState({drawer: true, status: true})
  const {data: token} = useTokenContext()

  useEffect(() => {
    var channel = MainPusher(token)?.subscribe(`private-comment.message.${auth?.currentUser?.id}`)
    channel?.bind('CommentEvent', function (data) {
      if (data.type == 'message') {
        let item = data['item']
        item['admin'] = data['admin']
        if (auth?.currentUser?.id == item.from_user) {
          toast(data)
          setMessages((prevMessages) => [...prevMessages, item])
        }
      }
    })
  }, [])
  if (loading) {
    return <>loading</>
  }
  return (
    <div
      className='card-body overflow-auto'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        style={{overflow: 'auto', height: !drawerState.drawer ? '86%' : '80%'}}
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
      >
        {messages.map((message, index) => {
          const userInfo = message['user_send'] == 'admin' ? message.user : auth.currentUser
          const state = message['user_send'] === 'user' ? 'info' : 'primary'
          const templateAttr = {}
          // if (message.template) {
          //   Object.defineProperty(templateAttr, 'data-kt-element', {
          //     value: `template-${message.type}`,
          //   })
          // }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message['user_send'] === 'user' ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.type === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <img alt='Pic' src={userInfo?.['profile']} />
                      </div>
                      <div className='ms-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                        >
                          {userInfo?.['username']}
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          You
                        </a>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {/* <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} /> */}
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${message['user_send'] === 'user' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{__html: message['message']}}
                ></div>
              </div>
            </div>
          )
        })}
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='input-group mb-5'>
          <textarea
            className={`form-control form-control-flush mb-3 `}
            rows={1}
            disabled={loadingSend}
            data-kt-element='input'
            placeholder='Type a message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>
          {loadingSend ? (
            <span
              className='input-group-text'
              id='basic-addon2'
              style={{position: 'absolute', right: 0, background: 'none', border: 0}}
            >
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            ''
          )}
        </div>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            style={{position: 'absolute', left: '50%'}}
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export {CommentInner}
