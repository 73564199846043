import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ApplyStepAdminLoading = () => {
  return (
    <div style={{margin:30}}>
      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Skeleton height={50} width={'100%'} />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col-6'>
            <Skeleton height={50} width={'100%'} />
          </div>
          <div className='col-6'>
            <Skeleton height={50} width={'100%'} />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Skeleton height={50} width={'100%'} />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Skeleton height={50} width={'100%'} />
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col-6'>
            <Skeleton height={50} width={'100%'} />
          </div>
          <div className='col-6'>
            <Skeleton height={50} width={'100%'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyStepAdminLoading
