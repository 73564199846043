import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../../_elements/helpers'
import {useEffect, useState} from 'react'
import {apply, checkStep} from './requests/_requests'
import clsx from 'clsx'
import Comments from './components/Comments'
import Check from './components/Check'
import ApplyStepAdminLoading from '../../../../../../_elements/loading-skeleton/ApplyStepAdminLoading'

const Apply = () => {
  const [applyData, setApplyData] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const [comment, setComment] = useState([])

  useEffect(() => {
    apply(params.id).then((response) => {
      setApplyData(response.data?.['data'])
      setComment(response.data?.['comments'])

      setLoading(false)
    })
  }, [])

  if (loading) {
    return <ApplyStepAdminLoading/>
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
          <div className='align-self-center'>
            <Check id={params.id} step={6} key={6} />

          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Country insert</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{applyData?.['country_insert']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Level insert</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{applyData['level_insert']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Field insert</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{applyData['field_insert']}</span>
            </div>
          </div>
        </div>
      </div>
      <Comments comment={comment} step={6} />
    </>
  )
}
export default Apply
