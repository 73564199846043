import React, {FC, useEffect, useState} from 'react'
import {Dropdown1} from '../../../../../../../_elements/partials'
import { CommentInner} from './components/chat/CommentInner'
import {KTIcon} from '../../../../../../../_elements/helpers'

const Comments = ({comment=[], step=1}) => {
  const [loading, setLoading] = useState(false)



  if (loading) {
    return <h1>loading</h1>
  }
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header' id='kt_chat_messenger_header'>
        <div className='card-toolbar'>
          <div className='me-n3'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <i className='bi bi-three-dots fs-2'></i>
            </button>
            <Dropdown1 />
          </div>
        </div>
      </div>

      <div className='card-body p-9'>
        <CommentInner comment={comment} step={step} />
      </div>
    </div>
  )
}

export default Comments
