import {FC} from 'react'
import moment from 'moment'

type Props = {
  date?: string
}

const DateCell: FC<Props> = ({date}) => {
  let time = moment(date).format('YYYY-MM-DD hh:mm:ss');
  return (
    <div className='badge badge-light fw-bolder'>{time}</div>
  )
}

export {DateCell}
