import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ApplyRequestsListHeader} from './components/header/ApplyRequestsListHeader'
import {ApplyRequestsTable} from './table/ApplyRequestsTable'
import {ApplyRequestsEditModal} from './apply-request-edit-modal/ApplyRequestsEditModal'
import {KTCard} from '../../../../../_elements/helpers'

const ApplyRequestsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='h-md-100'>
        <ApplyRequestsListHeader />
        <ApplyRequestsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ApplyRequestsEditModal />}
    </>
  )
}

const ApplyRequestsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ApplyRequestsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ApplyRequestsListWrapper}
