/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {Link, useLocation, useParams} from 'react-router-dom'

import moment from 'moment'

import {response} from 'express'
import clsx from 'clsx'
import {KTIcon} from '../../../../_elements/helpers'
import {Modal} from 'react-bootstrap'
import {UserEditModalFormUpdate} from './UserEditModalFormUpdate'
import { useUserContext } from './UserContext'
const ProfileHeader = ({status_request = 1}) => {
  const location = useLocation()
  const {data:user, updateUser} = useUserContext()

  let path_name = location.pathname.split('/')
  let pathName = path_name[path_name.length - 1]
  let percent = Math.floor((100 / 7) * status_request)
  const params = useParams()
  const [check, setCheck] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleOpenModal = () => setShowModal(true)
  if (loading) {
    return <></>
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={user?.profile} alt='Metornic' />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {user?.username}
                  </a>
          
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {user?.rule}
                  </a>

                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {user?.email}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>
                <a
                  onClick={handleOpenModal}
                  className='btn btn-sm btn-primary me-3'
                >
                  Edit user
                </a>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-calendar-date-fill fs-3 me-2'></i>
                      <div className='fs-2 fw-bolder'>
                        {moment(user?.['created_at']).format('YYYY-MM-DD')}
                      </div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Register at</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-calendar-date-fill fs-3 me-2'></i>
                      <div className='fs-2 fw-bolder'>
                        {moment(user?.['updated_at']).format('YYYY-MM-DD')}
                      </div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Update profile at</div>
                  </div>
                </div>
              </div>

              {user?.rule === 'user' ? (
                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>{percent}%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: `${percent}%`}}
                    ></div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        {user?.rule === 'user' ? (
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fs-5 fw-bolder flex-nowrap'>
              {status_request >= 1 ? (
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      `nav-link  me-6 `,
                      pathName === 'verify_email' && 'active',
                      check.includes('1')
                        ? 'text-success text-active-success'
                        : 'text-active-primary'
                    )}
                    to='verify_email'
                  >
                    Verify Email
                  </Link>
                </li>
              ) : (
                ''
              )}
              {status_request >= 2 ? (
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      `nav-link  me-6 `,
                      pathName === 'personal' && 'active',
                      check.includes('2')
                        ? 'text-success text-active-success'
                        : 'text-active-primary'
                    )}
                    to='personal'
                  >
                    Personal
                  </Link>
                </li>
              ) : (
                ''
              )}
              {status_request >= 3 ? (
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      `nav-link  me-6 `,
                      pathName === 'education' && 'active',
                      check.includes('3')
                        ? 'text-success text-active-success'
                        : 'text-active-primary'
                    )}
                    to='education'
                  >
                    Education
                  </Link>
                </li>
              ) : (
                ''
              )}
              {status_request >= 4 ? (
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      `nav-link  me-6 `,
                      pathName === 'language' && 'active',
                      check.includes('4')
                        ? 'text-success text-active-success'
                        : 'text-active-primary'
                    )}
                    to='language'
                  >
                    Language
                  </Link>
                </li>
              ) : (
                ''
              )}
              {status_request >= 5 ? (
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      `nav-link  me-6 `,
                      pathName === 'documents' && 'active',
                      check.includes('5')
                        ? 'text-success text-active-success'
                        : 'text-active-primary'
                    )}
                    to='documents'
                  >
                    Documents
                  </Link>
                </li>
              ) : (
                ''
              )}
              {status_request >= 6 ? (
                <li className='nav-item'>
                  <Link
                    className={clsx(
                      `nav-link  me-6 `,
                      pathName === 'apply' && 'active',
                      check.includes('6')
                        ? 'text-success text-active-success'
                        : 'text-active-primary'
                    )}
                    to='apply'
                  >
                    Apply
                  </Link>
                </li>
              ) : (
                ''
              )}

              <li className='nav-item'>
                <Link
                  className={clsx(
                    `nav-link  me-6 `,
                    pathName === 'payment' && 'active',
                    check.includes('7') ? 'text-success text-active-success' : 'text-active-primary'
                  )}
                  to='payment'
                >
                  payment
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal show={showModal} dialogClassName=' modal-lg' onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>

        <Modal.Body> <UserEditModalFormUpdate handleCloseModal={handleCloseModal}/> </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

export {ProfileHeader}
