/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {useAuth} from '../../../../../app/modules/auth'
import {useStepContext} from '../../../../../app/Context/StepContext'

const ToolbarAccounting: FC = () => {
  const [progress, setProgress] = useState<string>('1')
  const [filter, setFilter] = useState<string>('1')
  const auth = useAuth()
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])
  const {data: stepData} = useStepContext()

  return (
    <>
      <div className='d-flex align-items-center me-5'>
        {/* begin::Input group */}
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-40px me-5'>
              <img alt='Logo' src={auth.currentUser?.['profile']} />
            </div>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {auth.currentUser?.['username']}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {auth.currentUser?.['email']}
              </a>
            </div>
          </div>
        </div>
        {/* end::Actions */}

        {/* end::Input group */}

        {/* begin::Input group */}
        <div className='d-flex align-items-center flex-shrink-0'>
          {/* begin::Desktop separartor */}
          <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
          {/* end::Desktop separartor */}

          {/* begin::Label */}
          {/* end::Label */}
        </div>
        {/* end::Input group */}
        {/* end::Toolbar start */}
      </div>
      {/* begin::Toolbar end */}
      <div className='d-flex align-items-center'>
        {/* begin::Input group */}
        <div className='me-3'>
          {/* begin::Select */}
          <div className='d-flex align-items-center flex-shrink-0'>
            {/* begin::Desktop separartor */}
            <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            {/* end::Desktop separartor */}

            {/* begin::Label */}
            <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block'>
              Progress:
            </span>
            {/* end::Label */}

            <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-success'>
              <div
                className='progress-bar rounded bg-success fs-7 fw-bold'
                role='progressbar'
                style={{width: `${Math.floor((100 / 7) * Number(stepData?.['statusRequest']))}%`}}
                aria-valuenow={Math.floor((100 / 7) * Number(stepData?.['statusRequest']))}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {Math.floor((100 / 7) * Number(stepData?.['statusRequest']))}%
              </div>
            </div>
          </div>
          {/* end::Content */}
        </div>
        {/* end::Input group- */}
      </div>
    </>
  )
}

export {ToolbarAccounting}
