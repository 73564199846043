import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react'

interface TokenContextProps {
  data: string
  updateToken: Dispatch<SetStateAction<string>>
}

const initialData: string = ''

const TokenContext = createContext<TokenContextProps>({
  data: initialData,
  updateToken: () => {},
})

interface ContextProviderProps {
  children: ReactNode
}

const TokenContextProvider = ({children}: ContextProviderProps) => {
  const [data, setData] = useState<string>(initialData)

  const updateToken: TokenContextProps['updateToken'] = (dataToken) => {
    setData(dataToken)
  }

  return <TokenContext.Provider value={{data, updateToken}}>{children}</TokenContext.Provider>
}

const useTokenContext = (): TokenContextProps => {
  const context = useContext(TokenContext)

  if (!context) {
    throw new Error('useTokenContext must be used within a TokenContextProvider')
  }

  return context
}

export {TokenContext, TokenContextProvider, useTokenContext}
