import Axios from '../../../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const DOCUMENTS = `${API_URL}/step/get/documents`
export const UPLOAD = `${API_URL}/step/update/document-upload`
export const PERSONAL_SEND = `${API_URL}/step/update/documents`
export const PERSONAL_SEND_DELETE = `${API_URL}/step/update/document-delete-upload`
// export const  COUNTRY= `${API_URL}/country/v1`

export function GetData() {
  return Axios.post(DOCUMENTS)
}

export function Upload(item) {
  return Axios.post(UPLOAD, item, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function DeleteUpload(data) {
  return Axios.post(PERSONAL_SEND_DELETE, data)
}
export function SendData(data) {
  return Axios.post(PERSONAL_SEND, data)
}
