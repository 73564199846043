import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ApplyStepUserLoading = () => {
  return (
    <div className='py-20 w-100 w-xl-700px px-9'>
      <div className='w-100'>
        <div className='mb-10 fv-row'>
          <div className='row mb-2' data-kt-buttons='true'>
            <div className='col'>
              <Skeleton height={50} width={'100%'} />
            </div>
          </div>
        </div>

        <div className='mb-10 fv-row'>
          <div className='row mb-2' data-kt-buttons='true'>
            <div className='col-6'>
              <Skeleton height={50} width={'100%'} />
            </div>
            <div className='col-6'>
              <Skeleton height={50} width={'100%'} />
            </div>
          </div>
        </div>

        <div className='mb-10 fv-row'>
          <div className='row mb-2' data-kt-buttons='true'>
            <div className='col'>
              <Skeleton height={50} width={'100%'} />
            </div>
          </div>
        </div>

        <div className='mb-10 fv-row'>
          <div className='row mb-2' data-kt-buttons='true'>
            <div className='col'>
              <Skeleton height={50} width={'100%'} />
            </div>
          </div>
        </div>

        <div className='mb-10 fv-row'>
          <div className='row mb-2' data-kt-buttons='true'>
            <div className='col-6'>
              <Skeleton height={50} width={'100%'} />
            </div>
            <div className='col-6'>
              <Skeleton height={50} width={'100%'} />
            </div>
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col-6'>
            <Skeleton height={50} width={'30%'} style={{float: 'left'}} />
          </div>
          <div className='col-6'>
            <Skeleton height={50} width={'40%'} style={{float: 'right'}} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyStepUserLoading
