import React, {createContext, useState, ReactNode, useContext} from 'react'

interface StatusContextProps {
  data: number
  updateStatus: (dataStatus: number) => void
}

const initialData = 1

const StatusContext = createContext<StatusContextProps>({
  data: initialData,
  updateStatus: () => {},
})

interface ContextProviderProps {
  children: ReactNode
}

const StatusContextProvider = ({children}: ContextProviderProps) => {
  const [data, setData] = useState(initialData)

  const updateStatus = (dataStatus: number) => {
    setData(dataStatus)
  }

  return <StatusContext.Provider value={{data, updateStatus}}>{children}</StatusContext.Provider>
}

const useStatusContext = (): StatusContextProps => {
  const context = useContext(StatusContext)

  if (!context) {
    throw new Error('useStatusContext must be used within a StatusContextProvider')
  }

  return context
}


export {StatusContext, StatusContextProvider, useStatusContext}
