import React, {FC} from 'react'
import {useStep} from '../../../../app/pages/user/dashboard/Hooks'
import {useAuth} from '../../../../app/modules/auth'
import { useStepContext } from '../../../../app/Context/StepContext'

const ToggleCommentDrawer: FC = () => {
  const {data: stepData} = useStepContext()

  const auth = useAuth()
  let comment = JSON.parse(auth?.currentUser?.['comment_status'])
  if (Array.isArray(comment) && comment.includes(stepData?.['status'])) {
    return (
      <button
        id='kt_drawer_comment_toggle'
        className='engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'
        title='Learn & Get Inspired'
        data-bs-toggle='tooltip'
        data-bs-placement='left'
        data-bs-dismiss='click'
        data-bs-trigger='hover'
      >
        Comment
      </button>
    )
  }
  return <div></div>
}

export {ToggleCommentDrawer}
