import Axios from '../../../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const VERIFY_EMAIL = `${API_URL}/step/get/verify-email`
export const PERSONAL = `${API_URL}/step/get/personal`
export const LANGUAGE = `${API_URL}/step/get/language`
export const EDUCATION = `${API_URL}/step/get/education`
export const DOCUMENTS = `${API_URL}/step/get/documents`
export const APPLY = `${API_URL}/step/get/apply`
export const CHECK = `${API_URL}/admin/check-data`
export const PAYMENT_CHECK_FISH = `${API_URL}/admin/payment/check-fish/`
export const GET_CHECKS = `${API_URL}/admin/get-check-data/`
export const OPEN_PAYMENT = `${API_URL}/admin/payment/open`
export const CLOSE_PAYMENT = `${API_URL}/admin/payment/close`
export const GET_PAYMENT = `${API_URL}/admin/payment/`
export const PAYMENT = `${API_URL}/admin/payment/update`
const GET_ADMIN_URL = `${API_URL}/admin/users/all-admin/`

export function verifyEmail(id) {
  return Axios.post(VERIFY_EMAIL, {id: id})
}

export function personal(id) {
  return Axios.post(PERSONAL, {id: id})
}

export function language(id) {
  return Axios.post(LANGUAGE, {id: id})
}

export function education(id) {
  return Axios.post(EDUCATION, {id: id})
}

export function documents(id) {
  return Axios.post(DOCUMENTS, {id: id})
}

export function apply(id) {
  return Axios.post(APPLY, {id: id})
}

export function checkStep(step_number, id) {
  return Axios.post(CHECK, {step_number: step_number, id: id})
}

export function getCheckStep(id) {
  return Axios.get(GET_CHECKS + id)
}

export function getPayment(id) {
  return Axios.get(GET_PAYMENT + id)
}


export function openPayment(id) {
  return Axios.post(OPEN_PAYMENT,{id:id})
}

export function closePayment(id) {
  return Axios.post(CLOSE_PAYMENT, {id: id})
}
export function payment(item) {
  return Axios.post(PAYMENT ,item)
}
export function checkFish(item,id) {
  return Axios.post(PAYMENT_CHECK_FISH+id, item)
}

const getAdmin = () => {
  return Axios.get(`${GET_ADMIN_URL}`)
}