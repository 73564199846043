import {ErrorMessage, Field, Formik, Form} from 'formik'
import {KTIcon, KTSVG} from '../../../../../_elements/helpers'
import {ChangeEmailValidation, VerifyEmailValidation} from './core/validation/Validation'
import {
  ChangeEmailRequest,
  ResendEmailRequest,
  VerifyEmailRequest,
} from './core/Requests/__requests_verify_email'
import {useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Button, Modal} from 'react-bootstrap'
import {ToastContainer, toast, useToast} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
const VerifyEmail = ({step_number, next, back}) => {
  const [errorRequest, setErrorRequest] = useState<boolean>(false)
  const auth = useAuth()

  const inits = {
    code: '',
  }

  const initEmail = {email: auth.currentUser?.email}
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)
  const submitRequest = async (value) => {
    await VerifyEmailRequest(value.code)
      .then((response) => {
        next(response.data.status.status_request)
      })
      .catch((errors) => {
        setErrorRequest(true)
      })
  }
  const resend = async () => {
    await ResendEmailRequest()
    return toast.success('The operation was successfuly', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const submitModal = async (res) => {
    const MySwal = withReactContent(Swal)

    await MySwal.fire({
      html: `Are you sure to do this?`,
      icon: 'info',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Ok, got it!',
      cancelButtonText: 'Nope, cancel it',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
      },
    }).then((response) => {
      if (!response.isConfirmed) {
        return MySwal.fire({
          icon: 'error',
          title: 'Your work has not saved',
          showConfirmButton: false,
          timer: 1500,
        })
      }
      ChangeEmailRequest(res)
        .then((res) =>
          MySwal.fire({
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => handleCloseModal())
        )
        .catch((err) => {
          MySwal.fire({
            icon: 'error',
            title: err?.response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    })
  }

  return (
    <div>
      <Formik
        validationSchema={VerifyEmailValidation}
        initialValues={inits}
        onSubmit={submitRequest}
      >
        {(error) => (
          <Form className='py-20 w-100 w-xl-700px px-9' id='kt_create_account_form'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>Verify Email</h2>

                <div className='text-gray-400 fw-bold fs-6'>
                  If you need more change email
                  <a onClick={handleShowModal} className='link-primary fw-bolder'>
                    {' '}
                    Change Email
                  </a>
                  .
                </div>
              </div>
              {errorRequest ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>Code is not true</div>
                </div>
              ) : (
                ''
              )}

              {/* Same as */}
              <div className='mb-10 fv-row'>
                <label className='d-flex align-items-center form-label  required mb-3'>
                  Code sent
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title={`The code has been sent to email ${auth.currentUser?.email} , write the code in this field`}
                  ></i>
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                  <div className='col'>
                    <Field
                      className={error.errors.code ? 'form-control is-invalid' : 'form-control '}
                      name='code'
                      id='code'
                      type='number'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='code' className='text-danger' />
                    </div>
                  </div>
                </div>

                <div className='form-text'>
                  <a onClick={() => resend()} className='link-primary fw-bolder'>
                    {' '}
                    Click here to resubmit
                  </a>
                </div>
              </div>
            </div>
            <div className='d-flex flex-stack pt-10'>
              <div className='mr-2'>
                <button
                  onClick={back}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              </div>

              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  <span className='indicator-label'>
                    Submit and Next
                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal show={showModal} dialogClassName=' modal-lg' onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={ChangeEmailValidation}
          initialValues={initEmail}
          onSubmit={submitModal}
        >
          {(error) => (
            <Form id='kt_create_account_form'>
              <Modal.Body>
                <label className='d-flex align-items-center form-label  required mb-3'>
                  Write your email:
                </label>

                <Field
                  className={error.errors.email ? 'form-control is-invalid' : 'form-control '}
                  name='email'
                  id='email'
                  type='email'
                  placeholder='info@otters.ir'
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='email' className='text-danger' />
                </div>
                <div className='text-muted m-2'>
                  The entered email must not have been registered before
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant='primary' type='submit'>
                  Save changes
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <ToastContainer />
    </div>
  )
}

export default VerifyEmail
