import {Navigate, Routes, Route, Outlet, useParams, Link} from 'react-router-dom'

import {ProfileHeader} from './ProfileHeader'
import {useEffect, useState} from 'react'
import {userData as userDataRequest} from './_requests'
import Admin from './components/Admin'
import { UserContextProvider, useUserContext } from './UserContext'
import { PageLink } from '../../../../_elements/layout/core'
import { useAuth } from '../../../modules/auth'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => {


  const {currentUser} =useAuth()
  const {data,updateUser} =useUserContext()
  useEffect(()=>{
    updateUser(currentUser)
  },[])


  return (
    <>
      <UserContextProvider>
        <Routes>
          <Route
            element={
              <>
                <ProfileHeader
                  status_request={data['apply_status']?.['status_request']}
                />
                <div className='alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-10'>
                  <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                    ...
                  </span>
                  <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                    <h5 className='mb-1'>This is an alert</h5>
                    <span>
                      The alert component can be used to highlight certain parts of your page for
                      higher content visibility.
                    </span>
                  </div>

                  <button
                    type='button'
                    className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                    data-bs-dismiss='alert'
                  >
                    <span className='svg-icon svg-icon-2x svg-icon-light'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='black'
                        ></rect>
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='black'
                        ></rect>
                      </svg>
                    </span>
                  </button>
                </div>
                <Outlet />
              </>
            }
          >
            <Route
              index
              element={
                <>
                  <Admin />
                </>
              }
            />
          </Route>
        </Routes>
      </UserContextProvider>
    </>
  )
}

export default ProfilePage
