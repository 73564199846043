/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_elements/helpers'
import {PageTitle} from '../../../../_elements/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ListsWidget5,
} from '../../../../_elements/partials/widgets'
import {ApplyRequestsListWrapper} from '../requests-list/requests-list/ApplyRequestsList'
import {getRequests, getUsers} from './_requests'
import DashboardLoading from '../../../../_elements/loading-skeleton/DashboardLoading'

const DashboardPage: FC = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const [request, setRequest] = useState({})
  useEffect(() => {
    Promise.all([getUsers(), getRequests()]).then((responses) => {
      setRequest(responses[1])
      setUser(responses[0])
      setLoading(false)
    })
  }, [])
  if (loading) {
    return (
      <div>
        <DashboardLoading />
      </div>
    )
  }
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <CardsWidget20
            request={request}
            className='h-md-100 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <CardsWidget7
            user={user}
            className='h-md-100 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}

        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        {/* <div className='col-xxl-4'>
          <ListsWidget5 className='' />
        </div> */}
        <div className='col-xl-12'>
          <ApplyRequestsListWrapper />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
