import * as Yup from 'yup'

export const SchoolValidation = Yup.object({
  schoolName: Yup.string().required('This field is required'),
  priceAF: Yup.string().required('This field is required'),
  price: Yup.string().required('This field is required'),
  link: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
})

export const PaymentValidation = Yup.object({
  item_name: Yup.string().required('This field is required'),
  name_card: Yup.string().required('This field is required'),
  bank_name: Yup.string().required('This field is required'),
  number_card: Yup.string().required('This field is required'),
  notes: Yup.string().required('This field is required'),
})
