import Axios from '../../../../../../../_requests/_request_main'


const API_URL = process.env.REACT_APP_API_URL

export const EDUCATION = `${API_URL}/step/get/education`
export const EDUCATION_SEND = `${API_URL}/step/update/education`
export const  COUNTRY= `${API_URL}/country/v1`


export function GetData() {

  return Axios.post(EDUCATION)
}

export function GetCountry() {

  return Axios.get<{result: boolean}>(COUNTRY)
}

export function SendData(data) {

    return Axios.post(EDUCATION_SEND,data)

  }
  