import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../../_elements/helpers'
import {useEffect, useState} from 'react'
import {checkStep, language} from './requests/_requests'
import clsx from 'clsx'
import Comments from './components/Comments'
import Check from './components/Check'
import ApplyStepAdminLoading from '../../../../../../_elements/loading-skeleton/ApplyStepAdminLoading'

const Language = () => {
  const [languageData, setLanguageData] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const [comment, setComment] = useState([])

  useEffect(() => {
    language(params.id).then((response) => {
      setLanguageData(response.data?.['data'])
      setComment(response.data?.['comments'])

      setLoading(false)
    })
  }, [])
  if (loading) {
    return <ApplyStepAdminLoading />
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
          <div className='align-self-center'>
            <Check id={params.id} step={4} key={4} />

          </div>
        </div>
        <div className='card-body p-9'>
          <p>Write your text</p>
        </div>
      </div>
      <div className='row "d-flex justify-content-center'>
        {JSON.parse(languageData['language']).map((value) => (
          <div className='card col-5 m-2 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Language skills</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Certificate</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['certificate']}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>reading score</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['reading_score']}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Specking score</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['specking_score']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Writing score</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['writing_score']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Listening score</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['listening_score']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Score</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['score']}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Comments comment={comment} step={4} />
    </>
  )
}
export default Language
