import Axios from '../../../../../../../_requests/_request_main'


const API_URL = process.env.REACT_APP_API_URL

export const LANGUAGE = `${API_URL}/step/get/language`
export const LANGUAGE_SEND = `${API_URL}/step/update/language`



export function GetData() {

  return Axios.post(LANGUAGE)
}

export function SendData(data) {

    return Axios.post(LANGUAGE_SEND,data)

  }
 