import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ProfileLoading = () => {
  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <Skeleton height={'100px'} width={'100px'} style={{borderRadius: '100%'}} />
              </div>
            </div>
            <div className='row' style={{marginTop:'13px'}}>
              <div className='col-6'>
                <Skeleton width={'500px'} height={30} />
                <Skeleton width={'500px'} height={30} style={{marginTop: '10px'}} />
              </div>

              <div className='col-6'>
                <Skeleton width={'500px'} height={30} />
                <Skeleton width={'500px'} height={30} style={{marginTop: '10px'}} />
              </div>
            </div>
          </div>
          <Skeleton width={'100%'} height={30} style={{margin: '10px'}} />
        </div>
      </div>
    </div>
  )
}
export default ProfileLoading
