import Axios from '../../../../../../../_requests/_request_main'

const API_URL = process.env.REACT_APP_API_URL

export const PERSONAL = `${API_URL}/step/get/personal`
export const PERSONAL_SEND = `${API_URL}/step/update/personal`
export const PERSONAL_SEND_UPLOAD = `${API_URL}/step/update/personal-upload`
export const PERSONAL_SEND_UPLOAD_DELETE = `${API_URL}/step/update/personal-upload-delete`
export const COUNTRY = `${API_URL}/country/v1`

export function GetData() {
  return Axios.post(PERSONAL)
}

export function GetCountry() {
  return Axios.get<{result: boolean}>(COUNTRY)
}

export function SendData(data) {
  return Axios.post(PERSONAL_SEND, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export function UploadProfile(data) {
  return Axios.post(PERSONAL_SEND_UPLOAD, data)
}
export function DeleteUploadProfile(data) {
  return Axios.post(PERSONAL_SEND_UPLOAD_DELETE, data)
}
