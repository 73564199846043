import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react'

interface StepContextProps {
  data: object
  updateStep: Dispatch<SetStateAction<object>>
}

const initialData: object = {}

const StepContext = createContext<StepContextProps>({
  data: initialData,
  updateStep: () => {},
})

interface ContextProviderProps {
  children: ReactNode
}

const StepContextProvider = ({children}: ContextProviderProps) => {
  const [data, setData] = useState<object>(initialData)

  const updateStep = (dataStep: object) => {
    setData(dataStep)
  }

  return <StepContext.Provider value={{data, updateStep}}>{children}</StepContext.Provider>
}

const useStepContext = (): StepContextProps => {
  const context = useContext(StepContext)

  if (!context) {
    throw new Error('useStepContext must be used within a StepContextProvider')
  }

  return context
}

export {StepContext, StepContextProvider, useStepContext}
