/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'

import Pusher from 'pusher-js'
import {send} from '../_requests'
import {
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../../../../../../_elements/helpers'
import UserContext from '../../../../UserContext'
import {MainPusher} from '../../../../../../../../../_requests/_pusher_main'
import {useTokenContext} from '../../../../../../../../Context/TokenContext'
import {useAuth} from '../../../../../../../../modules/auth'

const bufferMessages = defaultMessages

const CommentInner = ({comment, step}) => {
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<Array<string>>([])
  const userInfos = useContext(UserContext)['user']
  const [loadingSend, setLoadingSend] = useState<boolean>(false)
  const commentBox = useRef<HTMLDivElement>(null)
  const {currentUser} = useAuth()
  useEffect(() => {
    if (comment) {
      setMessages(comment)
    }
  }, [comment])
  const sendMessage = () => {
    setLoadingSend(true)
    let item = {message: message, user_to: userInfos['id'], step_number: step}
    send(item).then((response) => {
      setLoadingSend(false)
      setMessages((prevMessages) => [...prevMessages, response.data])
      setMessage('')
    })
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }
  const {data: token} = useTokenContext()

  useEffect(() => {
    var channel = MainPusher(token)?.subscribe(`private-comment.message.${userInfos?.['id']}`)
    channel?.bind('CommentEvent', function (data) {
      if (data['item'].step_number) {
        let item = data['item']
        if (userInfos && userInfos?.['id'] != item.to_user) {
          item['admin'] = data['admin']
          // toastNotify(data)
          setMessages((prevMessages) => [...prevMessages, item])
        }
      }
    })
  }, [])
  useEffect(() => {
    if (commentBox.current) {
      commentBox.current.scrollTop = commentBox.current.scrollHeight // Add null check before accessing properties
    }
  }, [messages])

  useEffect(() => {
    setMessage('')
  }, [userInfos])

  return (
    <div className='card-body' id='kt_comment_messenger_body'>
      <div
        className={clsx('scroll-y me-n5 pe-5', 'h-300px h-lg-500px')}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_comment_messenger_header, #kt_comment_messenger_footer'
        }
        ref={commentBox}
        data-kt-scroll-wrappers={'#kt_content, #kt_app_content, #kt_comment_messenger_body'}
        data-kt-scroll-offset='5px'
      >
        {messages.length !== 0 && userInfos ? (
          messages.map((message, index) => {
            const userInfo = message['user_send'] === 'user' ? userInfos : currentUser
            const state = message['user_send'] === 'user' ? 'info' : 'primary'

            const contentClass = `d-flex justify-content-${
              message['user_send'] === 'user' ? 'start' : 'end'
            } mb-10`
            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10')}
                // {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message['user_send'] === 'user' ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    {message['user_send'] === 'user' ? (
                      <>
                        <div className='symbol  symbol-35px symbol-circle '>
                          <img alt='Pic' src={userInfo['profile']} />
                        </div>
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                          >
                            {userInfo['username']}
                          </a>
                          {/* <span className='text-muted fs-7 mb-1'>{message.time}</span> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='me-3'>
                          {/* <span className='text-muted fs-7 mb-1'>{message.time}</span> */}
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                          >
                            {userInfo['username']}
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle '>
                          <img alt='Pic' src={userInfo['profile']} />
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-${message['user_send'] === 'user' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                  >
                    {message['message']}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div>
            <img
              src={toAbsoluteUrl('/media/icons/duotune/communication/com003.svg')}
              style={{
                width: '26%',
                marginRight: 'auto',
                marginLeft: 'auto',
                display: 'block',
                opacity: '28.1%',
              }}
            />
            <h2 className='text-center font-weight-bold m-3 text-muted'>Not have message</h2>
          </div>
        )}
      </div>

      <div className='card-footer pt-4' id='kt_comment_messenger_footer'>
        <div className='input-group mb-5'>
          <textarea
            className={`form-control form-control-flush mb-3 `}
            rows={1}
            disabled={loadingSend}
            data-kt-element='input'
            placeholder='Type a message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>
          {loadingSend ? (
            <span
              className='input-group-text'
              id='basic-addon2'
              style={{position: 'absolute', right: 0, background: 'none', border: 0}}
            >
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            ''
          )}
        </div>
        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export {CommentInner}
