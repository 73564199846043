import React, {useEffect, useRef, useState} from 'react'
import {useDropzone} from 'react-dropzone'

import clsx from 'clsx'
import {Modal, Button} from 'react-bootstrap'
import ImageCropper from './ImageCropper'

import {blob} from 'stream/consumers'
import { toAbsoluteUrl } from '../../../../../../_elements/helpers'
const CropAndUploadFile = ({data, defaultData = [] as any[]}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [imageHover, setImageHover] = useState('')
  const [cropState, setCropState] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [imageCropped, setImageCropped] = useState({})
  useEffect(() => {
    setSelectedFiles(defaultData)
  }, [])

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  useEffect(() => {
    if (cropState) {
      handleShow()
    } else {
      handleClose()
    }
  }, [cropState])
  const onDrop = async (acceptedFiles) => {
    let reader = new FileReader()

    setCropState(URL.createObjectURL(acceptedFiles[0]))
  }
  const onCrop = async () => {
    const fileCrop = new FileReader()
    fileCrop.onload = function () {
      setSelectedFiles([
        {
          file: this.result,
          format: imageCropped['type'].split('/')[1],
        },
      ])
      data(this.result)
    }
    fileCrop.readAsDataURL(imageCropped as Blob)
  }
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
    onDrop,
    multiple: false,
  })
  const handleDelete = (file) => {
    setSelectedFiles(selectedFiles.filter((val) => val.file != file.file))
    data(selectedFiles)
  }

  return (
    <div className='form'>
      <div className='fv-row'>
        <div className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <div {...getRootProps()} className='dz-message needsclick'>
            <i className='ki-duotone ki-file-up fs-3x text-primary'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <div className='ms-4'>
              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                {isDragActive ? 'Drop the files here' : 'Drop files here or click to upload.'}
              </h3>
              <span className='fs-7 fw-semibold text-gray-400'>Upload up to 10 files</span>
            </div>
          </div>

          <input {...getInputProps()} accept='' />
          {selectedFiles.length > 0 && (
            <div>
              <h4>Selected Files:</h4>

              <div className='row ' style={{width: '100%'}}>
                {selectedFiles.map((file) => {
                  if (['jpeg', 'jpg', 'png'].includes(file.format)) {
                    return (
                      <div
                        key={file.toString()}
                        style={{transition: '1s'}}
                        className={clsx(
                          'mb-2 mt-2 grid ',
                          selectedFiles.length <= 4 ? `col-${12 / selectedFiles.length}` : 'col-3'
                        )}
                      >
                        <div
                          style={{
                            width: 100,
                            height: 100,
                            backgroundImage: `url("${file.file.toString()}")`,
                            backgroundSize: 'cover',
                            transition: '1s',
                          }}
                          onMouseEnter={() => setImageHover(file.file)}
                          onMouseLeave={() => setImageHover('')}
                        >
                          <button
                            className={clsx(
                              'g-col-2 btn btn-danger fa-solid fa-eraser',
                              file.file == imageHover ? 'opacity-75' : 'opacity-0'
                            )}
                            style={{transition: '0.5s'}}
                            onClick={() => {
                              handleDelete(file)
                            }}
                          ></button>
                        </div>
                      </div>
                    )
                  }
                  // if (file.format == undefined) {
                  //   setSelectedFiles(selectedFiles.filter((val) => val != file))
                  // }
                  return (
                    <div
                      key={file.toString()}
                      style={{transition: '1s'}}
                      className={clsx(
                        'mb-2 mt-2 grid ',
                        selectedFiles.length <= 4 ? `col-${12 / selectedFiles.length}` : 'col-3'
                      )}
                    >
                      <div
                        style={{
                          width: 100,
                          height: 100,
                          backgroundImage: `url("${toAbsoluteUrl(
                            `/media/svg/files/${file.format}.svg`
                          )}")`,
                          backgroundSize: 'cover',
                          transition: '1s',
                        }}
                        onMouseEnter={() => setImageHover(file.file)}
                        onMouseLeave={() => setImageHover('')}
                      >
                        <button
                          className={clsx(
                            'g-col-2 btn btn-danger fa-solid fa-eraser',
                            file.file == imageHover ? 'opacity-75' : 'opacity-0'
                          )}
                          style={{transition: '0.5s'}}
                          onClick={() => {
                            handleDelete(file)
                          }}
                        ></button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cropState ? (
            <ImageCropper
              src={cropState}
              onCrop={(res: Blob) => {
                setImageCropped(res)
              }}
            />
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              onCrop()
              handleClose()
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CropAndUploadFile
