import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../../_elements/helpers'
import {useEffect, useState} from 'react'
import {checkStep, education} from './requests/_requests'
import clsx from 'clsx'
import Comments from './components/Comments'
import Check from './components/Check'
import ApplyStepAdminLoading from '../../../../../../_elements/loading-skeleton/ApplyStepAdminLoading'

const Education = () => {
  const [educationData, setEducationData] = useState([])
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const [comment, setComment] = useState([])

  useEffect(() => {
    education(params.id).then((response) => {
      setEducationData(response.data?.['data'])
      setComment(response.data?.['comments'])

      setLoading(false)
    })
  }, [])
  if (loading) {
    return <ApplyStepAdminLoading />
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>

          <div className='align-self-center'>
            <Check id={params.id} step={3} key={3} />

          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Education level</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{educationData?.['education_level']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Middle name</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{educationData['country_education']}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Family name</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{educationData['field_study']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Address</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{educationData['year_graduation']}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Emergency</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{educationData['GPA']}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row "d-flex justify-content-center'>
        {JSON.parse(educationData['education_history']).map((value) => (
          <div className='card col-5 m-2 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Education history</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>School name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['school_name']}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Field study</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['field_study']}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Level</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['level']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Level</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['level']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Language study</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['language_study']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Medium instruction</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['medium_instruction']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Address</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['address']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Graduated at</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['graduated_at']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>GPA</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['GPA']}</span>
                </div>
              </div>{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Study from</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{value?.['study_from']}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Comments comment={comment} step={3} />
    </>
  )
}
export default Education
