/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import {useCommentDrawer, useStep} from '../../../../app/pages/user/dashboard/Hooks'
import {CommentInner} from './CommentInner'
import {useAuth} from '../../../../app/modules/auth'
import {Toast} from 'react-bootstrap'
import {useStepContext} from '../../../../app/Context/StepContext'

const DrawerComment: FC = () => {
  const {data: stepData} = useStepContext()

  const stepsName = [
    'Verify email',
    'Personal',
    'Education',
    'Language',
    'Documents',
    'Apply',
    'Payment',
  ]
  const [drawerState, setDrawerState] = useState({drawer: true, status: true})
  const drawer = useCommentDrawer(drawerState)
  const [toastNotifyData, setToastNotifyData] = useState(false)

  const auth = useAuth()
  const [show, setShow] = useState(false)
  const toastNotify = (data) => {
    setShow(true)
    setToastNotifyData(data)
    const audio = new Audio(toAbsoluteUrl('/media/noth.mp3'))
    audio.play()
  }
  let comment = JSON.parse(auth?.currentUser?.['comment_status'])
  if (Array.isArray(comment) && comment.includes(stepData?.['status'])) {
    return (
      <div>
        <div
          className={drawerState.drawer ? 'bg-body' : 'bg-body'}
          id='kt_drawer_comment'
          style={
            !drawerState.drawer
              ? {position: 'absolute', right: 0, overflow: 'hidden', height: '50em', top: 149}
              : {width: '500px !important', overflow: 'hidden'}
          }
          {...(drawerState.drawer
            ? {
                'data-kt-drawer': 'true',
                'data-kt-drawer-name': 'chat',
                'data-kt-drawer-overlay': 'true',
                'data-kt-drawer-activate': 'true',
                'data-kt-drawer-width': "{default:'300px', 'md': '500px'}",
                'data-kt-drawer-direction': 'end',
                'data-kt-drawer-toggle': '#kt_drawer_comment_toggle',
                'data-kt-drawer-close': '#kt_drawer_comment_close',
              }
            : {})}
        >
          <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
            <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
              <div className='card-title'>
                <div className='d-flex justify-content-center flex-column me-3'>
                  <a
                    href='#'
                    className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                  >
                    Comment
                  </a>

                  <div className='mb-0 lh-1'>
                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-bold text-gray-400'>
                      {stepsName[+stepData?.['status'] - 1]}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className='card-toolbar'
                style={{position: 'fixed', bottom: 20, width: '39%', background: ' white'}}
              >
                <div className='me-2'>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                </div>
                {!drawerState.drawer ? (
                  <div
                    className='btn btn-sm btn-icon btn-active-light-primary'
                    onClick={() => {
                      setDrawerState({...drawerState, drawer: true})
                      const drawerComment = document.getElementById('kt_drawer_comment')
                      if (drawerComment) {
                        drawerComment.className = 'bg-body drawer drawer-end '
                      }
                    }}
                    id='kt_drawer_comment_close'
                  >
                    <KTIcon iconName='cross' className='fs-2' />
                  </div>
                ) : (
                  ''
                )}
                {drawerState.drawer ? (
                  <div>
                    <div
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      id='kt_drawer_comment_close'
                      onClick={() => {
                        setTimeout(() => {
                          setDrawerState({...drawerState, drawer: false})
                          const drawerComment = document.getElementById('kt_drawer_comment')
                          if (drawerComment) {
                            drawerComment.className = 'bg-body'
                          }
                        }, 500)
                      }}
                    >
                      <KTIcon iconName='pin' className='fs-2' />
                    </div>
                    <div
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      id='kt_drawer_comment_close'
                    >
                      <KTIcon iconName='cross' className='fs-2' />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <CommentInner isDrawer={true} toast={toastNotify} />
          </div>
        </div>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
          style={{position: 'fixed', top: '88%', left: '3%'}}
        >
          <Toast.Header>
            <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
            <strong className='me-auto'>{toastNotifyData?.['admin']?.['username']}</strong>
            <small>now</small>
          </Toast.Header>
          <Toast.Body>{toastNotifyData?.['item']?.['message']}</Toast.Body>
        </Toast>
      </div>
    )
  }
  return <div></div>
}

export {DrawerComment}
