import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_elements/layout/core'
import {UsersListWrapper} from './users-list/UsersList'
import ProfilePage from './profile/ProfilePage'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Profile',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Users list</PageTitle>
              <UsersListWrapper />
            </>
          }
        />
        <Route
          path='/:id/*'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Profile Page</PageTitle>
              <ProfilePage />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/users' />} />
    </Routes>
  )
}

export default UsersPage
