import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const API_URL = process.env.REACT_APP_API_URL

let Axios = axios.create({
  baseURL: API_URL,
})

Axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (error.response.status == 500) {
        return toast.error('Server error !', {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
      }
      if (error.response.status == 422) {
        return toast.error(error.response.data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    }

    return Promise.reject(error)
  }
)
Axios.interceptors.request.use(
  (config) => {
    const localStorageValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let token = localStorageValue !== null ? JSON.parse(localStorageValue).data.token : 'no'
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default Axios
