import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {useListView} from '../../core/ListViewProvider'
import {User} from '../../core/_models'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<User>>
}

const ApplyRequestSelectionHeader: FC<Props> = ({tableProps}) => {
  const {isAllSelected, onSelectAll} = useListView()
  return (
    <th
      {...tableProps.column.getHeaderProps()}

      style={{cursor: 'pointer'}}
    >
      id
    </th>
  )
}

export {ApplyRequestSelectionHeader}
