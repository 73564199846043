import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_elements/helpers'
import {DeleteUpload, GetData, SendData, Upload} from './core/Requests/__requests_payment'
import moment from 'moment'
import UploadFile from './core/components/UploadFile'
import {useStatusContext} from '../../../../Context/StatusContext'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import ApplyStepUserLoading from '../../../../../_elements/loading-skeleton/ApplyStepUserLoading'

const Payment = ({step_number, next, back}) => {
  const [dataPay, setDataPay] = useState({})
  const [fish, setFish] = useState([])
  const [loading, setLoading] = useState(true)
  const [dic, setDic] = useState('')
  const [sumPrice, setSumPrice] = useState(0)
  const [schoolSelected, setSchoolSelected] = useState<any[]>([])
  const {data, updateStatus} = useStatusContext()

  useEffect(() => {
    GetData().then((res) => {
      setDataPay(res.data.data)
      if (res.data.data.fish) {
        setFish(JSON.parse(res.data.data.fish))
      }
      setLoading(false)
    })
  }, [])

  const upload = async (field) => {
    return await Upload(field).then((res) => res.data)
  }
  const deleteApi = async (field) => {
    return await DeleteUpload(field).then((res) => res.data)
  }
  const submit = () => {
    if (schoolSelected.length == 0) {
      return toast.success('The operation was successfuly', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
    SendData({schools_selected: schoolSelected, file: fish}).then((res) => {
      updateStatus(4)
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        icon: 'success',
        title: 'Your work has been saved',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        confirmButtonColor: 'rgb(70, 196, 127)',
      })
    })
  }

  if (loading) {
    return <ApplyStepUserLoading />
  }
  return (
    <div>
      <div
        id='kt_content_container'
        className='d-flex flex-column-fluid align-items-start container-xxl'
      >
        <div id='kt_content' className='content flex-row-fluid'>
          <div className='card'>
            <div className='card-body py-20'>
              <div id='print' className='mw-lg-950px mx-auto w-100'>
                <div className='d-flex justify-content-between flex-column flex-sm-row mb-19'>
                  <h4 className='fw-bolder text-gray-800 fs-2qx pe-5 pb-7'>INVOICE</h4>
                  <div className='text-sm-end'>
                    <a href='#'></a>
                    <div className='text-sm-end fw-semibold fs-4 text-muted mt-7'>
                      <div>{dataPay['invoice']}</div>
                    </div>
                  </div>
                </div>
                <div className='border-bottom pb-12'>
                  <div
                    className='d-flex flex-row-fluid bgi-no-repeat bgi-position-x-center bgi-size-cover card-rounded h-150px h-lg-250px mb-lg-20'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/pattern-4.jpg')})`}}
                  ></div>
                  <div className='d-flex justify-content-between flex-column flex-md-row'>
                    <div className='flex-grow-1 pt-8 mb-13'>
                      <div className='table-responsive border-bottom mb-14'>
                        <table className='table align-middle table-row-dashed gy-5'>
                          <thead>
                            <tr>
                              <th>...</th>
                              <th className='fw-bolder text-gray-500'>Name</th>
                              <th className='fw-bolder text-gray-500'>Price</th>
                              <th className='fw-bolder text-gray-500'>Price AF</th>
                              <th className='fw-bolder text-gray-500'>Link</th>
                              <th className='fw-bolder text-gray-500'>Description</th>
                            </tr>
                          </thead>
                          <tbody className='fs-6 fw-semibold text-gray-600'>
                            {JSON.parse(dataPay['schools']).map((val) => (
                              <tr>
                                <td>
                                  <div className='form-check form-check-custom form-check-success form-check-solid'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value={val.price}
                                      onChange={(e) => {
                                        let school = schoolSelected
                                        school = school.filter((value) => {
                                          return e.target.checked ? val : value !== val
                                        })
                                        setSchoolSelected((item: any[]) =>
                                          e.target.checked
                                            ? [...item, val]
                                            : school.filter((value) => value !== val)
                                        )
                                        setSumPrice(
                                          e.target.checked
                                            ? sumPrice + Number(val.price)
                                            : sumPrice - Number(val.price)
                                        )
                                      }}
                                    />
                                  </div>
                                </td>
                                <td> {val.school_name} </td>
                                <td> {val.price} </td>
                                <td> {val.priceAF} </td>
                                <td>
                                  <a href={val.link} className=' btn btn-light '>
                                    Web Site
                                  </a>
                                </td>
                                <td>
                                  <button
                                    onClick={() => setDic(val.description)}
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_scrollable_1'
                                    className='bi bi-textarea btn btn-light-primary'
                                  ></button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <table className='table'>
                          <thead>
                            <tr className='border-bottom fs-6 fw-bold text-muted text-uppercase'>
                              <th className='min-w-175px pb-9'>Description</th>
                              <th className='min-w-70px pb-9 text-end'>Hours</th>
                              <th className='min-w-100px pe-lg-6 pb-9 text-end'>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='fw-bold text-gray-700 fs-5 text-end'>
                              <td className='d-flex align-items-center pt-11'>
                                <i className='fa fa-genderless text-danger fs-1 me-2'></i>{' '}
                                {dataPay?.['item_name']}
                              </td>
                              <td className='pt-11'>
                                {' '}
                                {moment(dataPay?.['updated_at']).format('YYYY-MM-DD hh:mm:ss')}
                              </td>
                              <td className='pt-11 fs-5 pe-lg-6 text-dark fw-bolder'>
                                $ {sumPrice}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='d-flex flex-column mw-md-300px w-100'>
                        <div className='fw-semibold fs-5 mb-3 text-dark00'>BANK TRANSFER</div>
                        <div className='d-flex flex-stack text-gray-800 mb-3 fs-6'>
                          <div className='fw-semibold pe-5'>Account Name:</div>
                          <div className='text-end fw-norma'>{dataPay['name_card']} </div>
                        </div>
                        <div className='d-flex flex-stack text-gray-800 mb-3 fs-6'>
                          <div className='fw-semibold pe-5'>Card Number:</div>
                          <div className='text-end fw-norma'> {dataPay['number_card']} </div>
                        </div>
                        <div className='d-flex flex-stack text-gray-800 mb-3 fs-6'>
                          <div className='fw-semibold pe-5'>Bank Name:</div>
                          <div className='text-end fw-norma'> {dataPay['bank_name']} </div>
                        </div>
                      </div>
                    </div>
                    <div className='pt-10 pt-md-0 m-5'>
                      <div className='d-flex justify-content-between'>
                        <div className='text-gray-800 fs-5 fw-bold'>Total Amount:</div>
                        <div className='text-dark00 fs-2qx fw-bolde'>$ {sumPrice} </div>
                      </div>
                      <div className='text-gray-400 fs-7 fw-semibold mt-3'>
                        Please make the payment by bank transfer to the provided account number. Use
                        the invoice number as the payment reference. Once the payment is confirmed,
                        your order will be processed.
                      </div>
                      <div className='mt-5'>
                        <UploadFile
                          data={(res) => setFish(res)}
                          deleteApi={deleteApi}
                          upload={upload}
                          field={{}}
                          defaultData={fish}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='pt-30'>
                  <div className='d-flex justify-content-between fs-6'>
                    <div className='text-muted me-7'>
                      <button className='btn btn-warning m-5'>Print invoice</button>
                    </div>
                    <div className='text-muted'>...</div>
                    <div className='text-muted'>
                      {' '}
                      <button
                        className='btn btn-primary m-5'
                        type='button'
                        onClick={submit}
                        disabled={data >= 4}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='upload' aria-hidden='true' className='modal fade'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bold'>Update Email Address</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    fill='none'
                    height='24'
                    viewBox='0 0 24 24'
                    width='24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      fill='currentColor'
                      height='2'
                      opacity='0.5'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      width='16'
                      x='6'
                      y='17.3137'
                    />
                    <rect
                      fill='currentColor'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      width='16'
                      x='7.41422'
                      y='6'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form id='kt_modal_update_email_form' action='#' className='form'>
                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6'>
                  <span className='svg-icon svg-icon-2tx svg-icon-primary me-4'>
                    <svg
                      fill='none'
                      height='24'
                      viewBox='0 0 24 24'
                      width='24'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        fill='currentColor'
                        height='20'
                        opacity='0.3'
                        rx='10'
                        width='20'
                        x='2'
                        y='2'
                      />
                      <rect
                        fill='currentColor'
                        height='2'
                        rx='1'
                        transform='rotate(-90 11 14)'
                        width='7'
                        x='11'
                        y='14'
                      />
                      <rect
                        fill='currentColor'
                        height='2'
                        rx='1'
                        transform='rotate(-90 11 17)'
                        width='2'
                        x='11'
                        y='17'
                      />
                    </svg>
                  </span>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-semibold'>
                      <div className='fs-6 text-gray-700'>
                        Please note that a valid email address is required to complete the email
                        verification.
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fv-row mb-7'>
                  <div id='upload1' className='dropzone'>
                    <div className='dz-message needsclick'>
                      <i className='bi bi-file-earmark-arrow-up text-primary fs-3x'></i>
                      <div className='ms-4'>
                        <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                          Drop files here or click to upload.
                        </h3>
                        <span className='fs-7 fw-semibold text-gray-400'>
                          Upload up to 10 files
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    type='reset'
                  >
                    Discard
                  </button>
                  <button
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    type='submit'
                  >
                    <span className='indicator-label'>Submit</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_scrollable_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Modal title</h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
              >
                <i className='ki-duotone ki-cross fs-2x'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>

            <div className='modal-body' style={{minHeight: 500}}>
              <p>{dic}</p>
            </div>

            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
