import {useEffect, useState} from 'react'
import {checkStep, getCheckStep} from '../requests/_requests'
import clsx from 'clsx'

const Check = ({step, id}) => {
  const [check, setCheck] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCheckStep(id).then((response) => {
      if (response.data.includes(step)) {
        setCheck(true)
      }
      setLoading(false)
    })
  }, [])
  const onChange = () => {
    checkStep(step, id)
    setCheck(!check)
  }
  if (loading) {
    return <></>
  }
  return (
    <>
      <div
        style={{height: 47}}
        className={clsx(
          'form-check form-switch form-check-custom form-check-solid btn   align-self-center  d-inline-block m-3',
          check ? 'btn-success' : 'btn-danger'
        )}
      >
        <input
          className='form-check-input align-self-center'
          style={{backgroundColor: check ? 'rgb(0, 247, 27)' : 'rgb(255, 103, 103)'}}
          type='checkbox'
          checked={Boolean(check)}
          onChange={() => onChange()}
          id='flexSwitchDefault'
        />
        <label
          className='form-check-label text-white align-self-center'
          htmlFor='flexSwitchDefault'
        >
          check{' '}
        </label>
      </div>
    </>
  )
}

export default Check
