import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  SetStateAction,
  Dispatch,
} from 'react'

interface NotificationContextProps {
  data: object
  updateNotification: Dispatch<SetStateAction<object>>
}

const initialData: object = {}

const NotificationContext = createContext<NotificationContextProps>({
  data: initialData,
  updateNotification: () => {},
})

interface ContextProviderProps {
  children: ReactNode
}

const NotificationContextProvider = ({children}: ContextProviderProps) => {
  const [data, setData] = useState<object>(initialData)

  const updateNotification = (dataNotification: object) => {
    setData(dataNotification)
  }

  return <NotificationContext.Provider value={{data, updateNotification}}>{children}</NotificationContext.Provider>
}

const useNotificationContext = (): NotificationContextProps => {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationContextProvider')
  }

  return context
}

export {NotificationContext, NotificationContextProvider, useNotificationContext}
