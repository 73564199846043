import {FC} from 'react'




const BadgeCell = ({data,color}) => {
  return (
    <div className={`badge badge-${color} fw-bolder`}>{data}</div>
  )
}

export {BadgeCell}
