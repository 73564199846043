// @ts-nocheck
import {Column} from 'react-table'
import {ApplyRequestInfoCell} from './ApplyRequestInfoCell'
import {DateCell} from './DateCell'
import {ApplyRequestActionsCell} from './ApplyRequestActionsCell'
import {ApplyRequestSelectionCell} from './ApplyRequestSelectionCell'
import {ApplyRequestCustomHeader} from './ApplyRequestCustomHeader'
import {ApplyRequestSelectionHeader} from './ApplyRequestSelectionHeader'
import {User} from '../../core/_models'
import {BadgeCell} from './BadgeCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <ApplyRequestSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ApplyRequestSelectionCell  num={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ApplyRequestCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({...props}) => <ApplyRequestInfoCell request={props.data[props.row.index]['username']} />,
  },
  {
    Header: (props) => <ApplyRequestCustomHeader tableProps={props} title='status name' className='min-w-125px' />,
    id: 'status_name',
    Cell: ({...props}) => <BadgeCell data={props.data[props.row.index]['status_name']} color='danger'/>,

  },
  {
    Header: (props) => (
      <ApplyRequestCustomHeader tableProps={props} title='End Apply' className='min-w-125px' />
    ),
    id: 'end_apply',
    Cell: ({...props}) => <BadgeCell data={props.data[props.row.index]['end_apply']?'True':'False'} color='danger' />,

  },
  {
    Header: (props) => (
      <ApplyRequestCustomHeader tableProps={props} title='End payment' className='min-w-125px' />
    ),
    id: 'payment_check',
    Cell: ({...props}) => <BadgeCell data={props.data[props.row.index]['payment_check']?'True':'False'} color='success' />,

  },


  // {
  //   Header: (props) => (
  //     <ApplyRequestCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <ApplyRequestActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {usersColumns}
