import {useEffect, useState} from 'react'
import {response} from 'express'
import ApplyStepAdminLoading from '../../../../../../_elements/loading-skeleton/ApplyStepAdminLoading'
import { KTIcon } from '../../../../../../_elements/helpers'
import { getAdmin } from '../../../profile/_requests'

const Admin = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getAdmin().then((response) => {
      setUsers(response?.data?.['data'])
      setLoading(false)
    })
  }, [])
  if (loading) {
    return <ApplyStepAdminLoading />
  }
  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-126'>
        <div className={`card `}>
          {/* begin::Header */}
          <div className='card-header border-0'>
            <h3 className='card-title fw-bold text-dark'>Authors</h3>
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
              </button>
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body pt-2'>
            {/* begin::Item */}
            {users.map((value) => {
              return (
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5'>
                    <img src={value?.['profile']} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {value?.['username']}
                    </a>
                    <span className='text-muted d-block fw-semibold'>{value?.['email']}</span>
                  </div>
                  {/* end::Text */}
                </div>
              )
            })}

            {/* end::Text */}

            {/* end::Item */}
          </div>
          {/* end::Body */}
        </div>
      </div>

      {/* <div className='col-xl-6'>
        <ListsWidget5 className='mb-5 mb-xxl-8' />
      </div> */}
    </div>
  )
}
export default Admin
