import {Navigate, Routes, Route, Outlet, useParams, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_elements/layout/core'

import {Documents} from './components/Documents'
import {ProfileHeader} from './ProfileHeader'
import {useEffect, useState} from 'react'
import {userData as userDataRequest} from './_requests'
import VerifyEmail from './components/VerifyEmail'
import Personal from './components/Personal'
import Education from './components/Education'
import Language from './components/Language'
import Apply from './components/Apply'
import Payment from './components/Payment'
import Admin from './components/Admin'
import {KTIcon} from '../../../../../_elements/helpers'
import UserContext from './UserContext'
import ProfileLoading from '../../../../../_elements/loading-skeleton/ProfileLoading'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => {
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const params = useParams()
  useEffect(() => {
    userDataRequest(params.id).then((response) => {
      setUserData(response.data)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <div>
        <ProfileLoading />
      </div>
    )
  }

  return (
    <>
      <UserContext.Provider value={userData}>
        <Routes>
          <Route
            element={
              <>
                <ProfileHeader
                  status_request={userData['apply_status']?.['status_request']}
                  user={userData['user']}
                />
                <div className='alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-10'>
                  <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                    ...
                  </span>
                  <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                    <h5 className='mb-1'>This is an alert</h5>
                    <span>
                      The alert component can be used to highlight certain parts of your page for
                      higher content visibility.
                    </span>
                  </div>

                  <button
                    type='button'
                    className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                    data-bs-dismiss='alert'
                  >
                    <span className='svg-icon svg-icon-2x svg-icon-light'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <rect
                          opacity='0.5'
                          x='6'
                          y='17.3137'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-45 6 17.3137)'
                          fill='black'
                        ></rect>
                        <rect
                          x='7.41422'
                          y='6'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(45 7.41422 6)'
                          fill='black'
                        ></rect>
                      </svg>
                    </span>
                  </button>
                </div>
                <Outlet />
              </>
            }
          >
            <Route
              index
              element={
                <>
                  {userData['user']?.['rule'] === 'admin' ? (
                    <Admin />
                  ) : (
                    <Navigate to={`/users/${params.id}/verify_email`} />
                  )}
                </>
              }
            />
            <Route
              path='/verify_email'
              element={
                <>
                  {userData['apply_status']?.['status_request'] < 1 ||
                  userData['user']?.['rule'] === 'admin' ? (
                    <Navigate to={`/users/${params.id}`} />
                  ) : (
                    ''
                  )}
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Verify email</PageTitle>
                  <VerifyEmail />
                </>
              }
            />
            <Route
              path='/personal'
              element={
                <>
                  {userData['apply_status']?.['status_request'] < 2 ||
                  userData['user']?.['rule'] === 'admin' ? (
                    <Navigate to={`/users/${params.id}`} />
                  ) : (
                    ''
                  )}
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Personal</PageTitle>
                  <Personal />
                </>
              }
            />
            <Route
              path='/education'
              element={
                <>
                  {userData['apply_status']?.['status_request'] < 3 ||
                  userData['user']?.['rule'] === 'admin' ? (
                    <Navigate to={`/users/${params.id}`} />
                  ) : (
                    ''
                  )}
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Education</PageTitle>
                  <Education />
                </>
              }
            />
            <Route
              path='language'
              element={
                <>
                  {userData['apply_status']?.['status_request'] < 4 ||
                  userData['user']?.['rule'] === 'admin' ? (
                    <Navigate to={`/users/${params.id}`} />
                  ) : (
                    ''
                  )}
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Language</PageTitle>
                  <Language />
                </>
              }
            />
            <Route
              path='documents'
              element={
                <>
                  {userData['apply_status']?.['status_request'] < 5 ||
                  userData['user']?.['rule'] === 'admin' ? (
                    <Navigate to={`/users/${params.id}`} />
                  ) : (
                    ''
                  )}
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
                  <Documents />
                </>
              }
            />
            <Route
              path='/apply'
              element={
                <>
                  {userData['apply_status']?.['status_request'] < 6 ? (
                    <Navigate to={`/users/${params.id}`} />
                  ) : (
                    ''
                  )}
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Apply</PageTitle>
                  <Apply />
                </>
              }
            />
            <Route
              path='payment'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Payment</PageTitle>
                  <Payment />
                </>
              }
            />
          </Route>
        </Routes>
      </UserContext.Provider>
    </>
  )
}

export default ProfilePage
